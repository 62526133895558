import { Stack } from '@mui/material';
import type {
  InventoryResource,
  Snapshot,
  SnapshotPropertiesVolumePropertiesInner,
} from '@repo/api-gw-sdk';
import { useState } from 'react';

import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import type { BackupVault } from '@/data/vaults/backupVault';

import { SnapshotDetails } from './snapshotDetails';
import { SnapshotRow } from './snapshotRow';

export type RestoreActionType = 'snapshot' | 'volume' | 'full-instance';

export const Ec2SnapshotRow = ({
  snap,
  vault,
  onRestore,
  isSelected,
  onSelect,
  resource,
}: {
  snap: Snapshot;
  vault: BackupVault | undefined;
  onRestore: (
    vols: SnapshotPropertiesVolumePropertiesInner[],
    actionType: RestoreActionType
  ) => void;
  isSelected?: boolean;
  onSelect?: () => void;
  resource: InventoryResource;
}) => {
  const [selectedVolume, setSelectedVolume] = useState<string>(
    snap.resourceProperties?.volumeProperties?.[0].volumeId || ''
  );
  const { fullInstanceRestore } = useFeatureFlags();

  if (!snap.resourceProperties?.volumeProperties) {
    return null;
  }

  const numOfVols = snap.resourceProperties.volumeProperties.length || 0;
  const onSelectSnapshot =
    numOfVols > 1
      ? (volId: string) => {
          setSelectedVolume(volId);
          onSelect?.();
        }
      : undefined;

  let enabledFullInstanceRestore = false;
  if (fullInstanceRestore?.filterType === 'exclude') {
    enabledFullInstanceRestore =
      !fullInstanceRestore.providerResourceIds.includes(
        resource.providerResourceId
      );
  } else if (fullInstanceRestore?.filterType === 'include') {
    enabledFullInstanceRestore =
      fullInstanceRestore.providerResourceIds.includes(
        resource.providerResourceId
      );
  }

  return (
    <Stack key={snap.id} className='w-full'>
      {enabledFullInstanceRestore ? (
        <SnapshotDetails
          resource={resource}
          key={snap.id}
          isSelected={isSelected}
          onSelect={onSelect}
          snap={snap}
          vault={vault}
          onRestore={(volumes, actionType) => onRestore(volumes, actionType)}
          onConvertSnapshot={(volumes) => onRestore(volumes, 'snapshot')}
        />
      ) : (
        snap.resourceProperties.volumeProperties.map((vol) => (
          <SnapshotRow
            resource={resource}
            key={`${snap.id}_${vol.volumeId}`}
            isSelected={selectedVolume === vol.volumeId}
            onSelect={() => onSelectSnapshot?.(vol.volumeId)}
            snap={snap}
            vault={vault}
            volume={vol.volumeName}
            onRestore={() => onRestore([vol], 'volume')}
            onConvertSnapshot={() => onRestore([vol], 'snapshot')}
          />
        ))
      )}
    </Stack>
  );
};
