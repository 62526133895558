import { Box, Typography } from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';

import { useRoles } from '@/contexts/useRoles';
import { Environments } from '@/data/inventory/data';

import { AutoClassification } from './autoClassiifcationButtons';

export function EnvironmentViewer({
  entity,
  setEnvironmentDetectionOff,
  restartEnvironmentDetection,
}: {
  entity: InventoryResource;
  setEnvironmentDetectionOff: () => void;
  restartEnvironmentDetection: () => void;
}) {
  const { isAuthorizedResource } = useRoles();

  if (entity.classifications?.environmentDetails?.isOverridden) {
    return (
      <>
        <AutoClassification
          testId='environment-auto-classification-buttons-override'
          textOn=''
          isAutoClassificationOn={false}
          restartDetectionClicked={() => restartEnvironmentDetection()}
          editOverridesClicked={() => setEnvironmentDetectionOff()}
          setEditorOpen={() => setEnvironmentDetectionOff()}
          disabled={
            !isAuthorizedResource('update:environment_classification', entity)
          }
        />
        <Box className='mt-6' sx={{ color: 'var(--mui-palette-primary-main)' }}>
          <Typography className='font-light'>
            {Environments[
              entity?.classifications.environmentDetails.environment ?? ''
            ]?.title ?? 'Not Detected'}
          </Typography>
        </Box>
      </>
    );
  }

  const textOn = entity.classifications?.environmentDetails?.environment
    ? 'This environment was detected:'
    : 'No environment was detected';

  return (
    <>
      <AutoClassification
        testId='environment-auto-classification-buttons-auto'
        textOn={textOn}
        isAutoClassificationOn={true}
        restartDetectionClicked={() => restartEnvironmentDetection()}
        editOverridesClicked={() => setEnvironmentDetectionOff()}
        setEditorOpen={() => setEnvironmentDetectionOff()}
        disabled={
          !isAuthorizedResource('update:environment_classification', entity)
        }
      />
      {!!entity.classifications?.environmentDetails?.environment &&
        Environments[
          entity.classifications?.environmentDetails?.environment
        ] && (
          <Box
            className='mt-6'
            sx={{ color: 'var(--mui-palette-primary-main)' }}
          >
            <Typography className='font-light'>
              {
                Environments[
                  entity.classifications?.environmentDetails?.environment
                ].title
              }
            </Typography>
          </Box>
        )}
    </>
  );
}
