import { Dialog, IconButton, Stack, Typography } from '@mui/material';
import type { BackupVault } from '@repo/api-gw-sdk';

import useBackupVaults from '@/data/vaults/useBackupVaults';

import BackupVaultCard from './BackupVaultCard';

export interface BackupVaultSelectionDialogProps {
  onClose: () => void;
  onSelect: (vault: BackupVault) => void;
}

export function BackupVaultSelectionDialog(
  props: BackupVaultSelectionDialogProps
) {
  const { data, loading } = useBackupVaults();
  return (
    <Dialog
      open={true}
      sx={{
        '& .MuiDialog-paper': { padding: '24px', maxWidth: '1100px' },
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{ mb: '20px' }}
      >
        <Typography variant='h6'>
          Please choose a target backup vault
        </Typography>
        <IconButton onClick={props.onClose}>
          <i className='material-symbols-close-rounded text-[22px]' />
        </IconButton>
      </Stack>
      <Typography variant='body1' sx={{ mb: '20px' }}>
        YOUR VAULTS
      </Typography>
      <div
        style={{
          width: '1004px',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: '24px',
        }}
      >
        {loading
          ? [...Array(3)].map((_, index) => (
              <BackupVaultCard
                key={index}
                loading={true}
                style={{ width: '320px', height: '184px' }}
              />
            ))
          : data?.map((vault) => (
              <BackupVaultCard
                style={{
                  width: '320px',
                  height: '184px',
                }}
                key={vault.id}
                data={vault}
                editable={false}
                onClick={props.onSelect}
              />
            ))}
      </div>
    </Dialog>
  );
}
