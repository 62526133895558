export const PropertyGroups: Record<string, { title: string }> = {
  primary: {
    title: 'Properties',
  },
  classifications: {
    title: 'Classifications',
  },
  'backup-details': {
    title: 'Backup details',
  },
  'source-location': {
    title: 'Source location',
  },
};
