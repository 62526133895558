import { MenuItem, Select, type SelectProps } from '@mui/material';
import type { Condition } from '@repo/api-gw-sdk';
import React, { useMemo } from 'react';

import {
  PropertyPolicyRelation,
  type FilterProperty,
} from '@/types/advanceFilter';

import { inlineDropdownStyle } from './ConditionEditor';
import { OPERATORS } from './OperatorSelect';

interface PropertySelectProps {
  condition?: Condition;
  properties: FilterProperty[][];
  onChange: (condition: Condition) => void;
}

export default function PropertySelect({
  condition,
  properties,
  onChange,
  ...props
}: PropertySelectProps & Omit<SelectProps<FilterProperty>, 'onChange'>) {
  const conditionProperty = useMemo(
    () => properties.flat().find((c) => c.name === condition?.property),
    [properties, condition?.property]
  );
  return (
    <Select
      sx={{ ...inlineDropdownStyle, fontWeight: 'bold' }}
      value={conditionProperty ?? ''}
      onChange={(event) => {
        const property = event.target.value as FilterProperty;
        onChange({
          ...condition,
          property: property.name,
          operator:
            OPERATORS[
              property.policyRelation ?? PropertyPolicyRelation.MultipleValues
            ][0].value,
          value: [],
        });
      }}
      {...props}
    >
      {properties.map((group, gi) =>
        group.map((p, i) => (
          <MenuItem
            divider={i === group.length - 1 && gi < properties.length - 1}
            key={p.name}
            value={p as never}
          >
            {p.displayName}
          </MenuItem>
        ))
      )}
    </Select>
  );
}
