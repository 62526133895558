import { Tooltip, Typography } from '@mui/material';

export const BackingUpTooltip = () => (
  <Tooltip title='Eon is processing data for this snapshot. Once the backup is complete, restore options will be available.'>
    <Typography
      className='font-semibold px-[12px]'
      color='var(--mui-palette-background-lightGray)'
    >
      Backing up...
    </Typography>
  </Tooltip>
);
