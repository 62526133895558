import { Box, Stack } from '@mui/material';

import { Icon } from '@/components/shared/icon';

type ClassificationChipMode = 'on' | 'off';
export function ClassificationChip({
  label,
  status,
}: {
  label: string;
  status: ClassificationChipMode;
}) {
  // TODO(benji) talk to Ran about colors

  const backgroundColor = status === 'on' ? '#d4ffe4' : '#F5F5F5';
  const color = status === 'on' ? '#44af88' : '#b2bec1';
  const icon =
    status === 'on'
      ? 'material-symbols-motion-play-outline-rounded'
      : 'mdi-stop-circle-outline';
  return (
    <Box
      sx={{
        borderRadius: '4px',
        height: '32px',
        width: '196px',
        fontWeight: 500,
        backgroundColor,
      }}
    >
      <Stack
        direction='row'
        alignItems={'center'}
        justifyContent={'space-evenly'}
      >
        <Box sx={{ paddingLeft: '4px' }}>
          <Icon color={color} iconClass={icon} />
        </Box>
        <Box
          sx={{
            fontSize: '12px',
            height: '32px',
            paddingRight: '12px',
            fontWeight: 500,
            lineHeight: '32px',
            color,
          }}
        >
          {label}
        </Box>
      </Stack>
    </Box>
  );
}
