import { Stack } from '@mui/material';
import {
  SnapshotStatus,
  type InventoryResource,
  type Snapshot,
} from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import { PointsInTimeCalendar } from '@/components/snapshots/pointsInTimeCalendar';
import { useDAL } from '@/data/dal';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { isDateValid } from '@/utils/dateTime';

import { RestoreSnapshotDrawer } from './restoreSnapshotDrawer';

export const PointsInTimeTab = ({
  entity,
  resourceId,
}: {
  resourceId: string;
  entity: InventoryResource;
}) => {
  const dal = useDAL();
  const [snapshotDataOpen, setSnapshotDataOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Date | undefined>();
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
  const { data: vaults } = useBackupVaults();

  const { body, isLoading } = dal.inventory.snapshots.list(
    resourceId,
    'eon',
    0,
    100
  );

  useEffect(() => {
    if (body?.data) {
      setSnapshots(
        body.data.filter(
          (x) =>
            (x.status as unknown) === 'PENDING_DISABLED' ||
            (x.status === SnapshotStatus.Completed &&
              isDateValid(x.pointInTime))
        )
      );
    }
  }, [body]);

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      height='100%'
      sx={{ margin: '24px auto 0' }}
      flexGrow='1'
      overflow='hidden'
    >
      <PointsInTimeCalendar
        isLoading={isLoading}
        onDateRangeChanged={() => {
          setSelected(undefined);
        }}
        events={(snapshots ?? []).map((x) => {
          const vault = vaults?.find((v) => v.id === x.vaultId);

          const date = isDateValid(x.pointInTime)
            ? x.pointInTime
            : x.createTime;

          const end = new Date(date);
          end.setMinutes(end.getMinutes() + 1);

          return {
            start: date,
            end,
            vaultName: vault?.name,
            indicatorColor: vault?.backgroundColor || null,
            status: x.status,
          };
        })}
        onDateSelected={(selected) => {
          if (selected) {
            setSnapshotDataOpen(true);
          }
          setSelected(selected ? new Date(selected.toDateString()) : undefined);
        }}
        selectedDate={selected}
      />
      {snapshotDataOpen && (
        <RestoreSnapshotDrawer
          resourceId={resourceId}
          vaults={vaults}
          snapshots={snapshots}
          selectedDate={selected}
          entity={entity}
          onClose={() => {
            setSelected(undefined);
            setSnapshotDataOpen(false);
          }}
        />
      )}
    </Stack>
  );
};
