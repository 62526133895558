import type {
  RestoreInstanceVolumeInput,
  SnapshotProperties,
  SnapshotPropertiesVolumePropertiesInner,
} from '@repo/api-gw-sdk';
import { AccountCloudEnum } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import { InstanceConfiguration } from './steps/instanceConfiguration';
import { SelectTargetAccount } from './steps/selectTargetAccount';
import { VolumeConfiguration } from './steps/volumeConfiguration';

import type { Encryption } from '../encryption/encryptionComponent';
import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export type VolumeConfigState = RestoreInstanceVolumeInput & {
  encryption?: Encryption;
  keepOriginalTags: boolean;
  keepOriginalSettings: boolean;
};

export interface RestoreBaseState {
  regionName?: string;
  AWSSubnetId?: string;
  securityGroupId?: string;
  instanceType?: string;
  instanceProfile?: string;
  tags?: Record<string, string>;
  keepOriginalTags?: boolean;
  volumesConfigState?: VolumeConfigState[];
}

export interface RestoreEc2InstanceState {
  resourceId: string;
  snapshotId: string;
  resourceName?: string;
  restoreAccountCloudProvider?: AccountCloudEnum;
  restoreAccountId?: string;
  sharedEncryption?: Encryption;
  crossStepsState: RestoreBaseState;
  initialState: RestoreBaseState;
  volumes: SnapshotPropertiesVolumePropertiesInner[];
}

const restoreEc2InstanceFlow: Step<RestoreEc2InstanceState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccount,
  next: [
    {
      name: 'InstanceConfiguration',
      Component: InstanceConfiguration,
      next: [
        {
          name: 'VolumeConfiguration',
          Component: VolumeConfiguration,
        },
      ],
    },
  ],
};

export const RestoreEc2InstanceWizard = ({
  resourceId,
  snapshotId,
  sourceRegion,
  volumes,
  resourceProperties,
}: {
  resourceId: string;
  snapshotId: string;
  sourceRegion?: string;
  volumes: SnapshotPropertiesVolumePropertiesInner[];
  resourceProperties?: SnapshotProperties;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: { id: resourceId, onClose: () => setIsOpen(false) },
    });

    return true;
  };

  const tags = resourceProperties?.tags || {};
  const volumesSettings: VolumeConfigState[] = volumes.map((vol) => ({
    ...vol,
    tags: vol.tags || {},
    isEncrypted: true,
    encryptionKeyId: undefined,
    keepOriginalTags: true,
    keepOriginalSettings: true,
  }));

  return (
    <Wizard<RestoreEc2InstanceState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreEc2InstanceFlow]}
      initialState={{
        snapshotId,
        resourceId,
        restoreAccountCloudProvider: AccountCloudEnum.Aws,
        volumes,
        sharedEncryption: {
          isEncrypted: true,
          encryptionKeyId: undefined,
          arn: undefined,
          mode: 'select',
        },
        crossStepsState: {
          volumesConfigState: volumesSettings,
        },
        initialState: {
          regionName: sourceRegion || 'us-east-1',
          AWSSubnetId: resourceProperties?.subnetId,
          securityGroupId: resourceProperties?.securityGroupIds?.[0],
          instanceProfile: resourceProperties?.instanceProfileName,
          instanceType: resourceProperties?.instanceType,
          tags,
          keepOriginalTags: true,
          volumesConfigState: volumesSettings,
        },
      }}
      title={`Restore EC2 Instance`}
      stepperLabels={[
        'Restore account',
        'Instance configuration',
        'Volume configuration',
      ]}
    />
  );
};
