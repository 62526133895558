import {
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import React, { useEffect, useState } from 'react';

import { useRoles } from '@/contexts/useRoles';
import { useDAL } from '@/data/dal';
import type { Action } from '@/data/inventory/actions';

import { BackupPoliciesTab } from './instance/tabs/backupPoliciesTab';
import { OverviewTab } from './instance/tabs/overviewTab';
import { PointsInTimeTab } from './instance/tabs/pointsInTimeTab';

import InfoSnackbar from '../infoSnackbar';
import { Loader } from '../layout/loading';
import { SidePanelWrapper } from '../layout/sidePanel/sidePanelWrapper';

export interface InstancePanelProps {
  entity: InventoryResource;
  onEntityChange?: () => Promise<void>;
  resourceId: string;
  fields: {
    id: string;
    title: (entity: InventoryResource) => string;
    group: string | undefined;
    value: (entity: InventoryResource) => React.ReactNode;
  }[];
  actions: Action<InventoryResource>[];
  openSections: {
    Properties: boolean;
    Classifications: boolean;
    BackupDetailsFields: boolean;
    SourceLocationFields: boolean;
  };
}

const InstancePanelComponent = (props: InstancePanelProps) => {
  const { entity, resourceId } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabs = [
    {
      name: 'Overview',
      Component: (props: InstancePanelProps) => <OverviewTab {...props} />,
    },
    ...('backupPolicies' in entity && entity.backupPolicies?.length
      ? [
          {
            name: 'Backup policies',
            Component: () => (
              <BackupPoliciesTab activePolicies={entity.backupPolicies} />
            ),
          },
        ]
      : []),
    ...(entity.resourceType
      ? [
          {
            name: 'Eon snapshots',
            Component: () => (
              <PointsInTimeTab entity={entity} resourceId={resourceId} />
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    // Sometimes switching across resources we get to a resource without the selected tab in that case we go to the first tab
    const navigateToNonExistingTab = selectedIndex >= tabs.length;
    if (navigateToNonExistingTab) {
      setSelectedIndex(0);
    }
  }, [selectedIndex, tabs.length]);

  const selectedTab = tabs[selectedIndex];

  return (
    <Stack direction='column' overflow='hidden' flexGrow='1'>
      {tabs.length > 1 && (
        <Tabs
          onChange={(e, i) => setSelectedIndex(i)}
          value={selectedIndex}
          className='px-[40px] min-h-[50px]'
          sx={{ '.MuiTabs-scroller': { display: 'flex' } }}
        >
          {tabs.map((value) => (
            <Tab
              key={value.name}
              label={value.name}
              className='text-[14px] font-normal'
              disableRipple
            />
          ))}
        </Tabs>
      )}
      {selectedTab?.Component && <selectedTab.Component {...props} />}
    </Stack>
  );
};

export const InstancePanel = ({
  isLoading,
  resourceId,
  onClose,
  entity,
  onEntityChange,
  fields,
  openSections,
  actions,
}: {
  isLoading: boolean;
  resourceId: string;
  onClose: () => void;
  entity: InventoryResource | undefined;
  onEntityChange?: () => Promise<void>;
  fields: InstancePanelProps['fields'];
  openSections: InstancePanelProps['openSections'];
  actions: InstancePanelProps['actions'];
}) => {
  const dal = useDAL();
  const { isAuthorizedResource } = useRoles();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('Scan Initiated');
  if (isLoading) {
    return <Loader />;
  }

  if (!entity) {
    onClose();
    return null;
  }

  return (
    <SidePanelWrapper
      title={
        <div className='flex items-center'>
          <Typography className='font-semibold'>Resource Details</Typography>

          <Tooltip title='Scan Now'>
            <IconButton
              className='ml-auto mr-20px'
              size='small'
              disabled={
                !isAuthorizedResource('create:jobs', entity) || disabled
              }
              onClick={() => {
                setSnackbarMessage('Scan Initiated');
                setSnackbarOpen(true);
                setDisabled(true);
                void dal.inventory.jobs
                  .createScanJob(resourceId)
                  .catch((e) => {
                    setSnackbarMessage('Scan Failed');
                    throw e;
                  })
                  .finally(() => {
                    setDisabled(false);
                  });
              }}
            >
              <i className='material-symbols-avg-pace-rounded' />
            </IconButton>
          </Tooltip>
          <InfoSnackbar
            message={snackbarMessage}
            isOpen={snackbarOpen}
            setSnackbarOpen={setSnackbarOpen}
          />
        </div>
      }
      close={onClose}
      hideBorder
      testIdPrefix='instance-panel'
    >
      <InstancePanelComponent
        entity={entity}
        onEntityChange={onEntityChange}
        actions={actions}
        fields={fields}
        openSections={openSections}
        resourceId={resourceId}
      />
    </SidePanelWrapper>
  );
};
