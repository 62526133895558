import Image from 'next/image';

import longDarkLogo from '../../../../public/images/logos/eon-logo-long-dark.png';
import longLogo from '../../../../public/images/logos/eon-logo-long.png';

export const EONLogo = ({
  className,
  dark,
}: {
  className?: string;
  dark?: boolean;
}) => {
  return (
    <Image
      priority
      className={className}
      src={dark ? longDarkLogo : longLogo}
      width={56}
      height={dark ? 21 : 24}
      alt='Eon'
    />
  );
};
