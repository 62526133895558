import { Button, OutlinedInput, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';

import type { StepProps } from '@/components/wizard/StepProps';

import type { UpsertCustomRoleState } from './upsertCustomRoleFlow';

export const CustomRoleNameSelection = (
  props: StepProps<UpsertCustomRoleState>
) => {
  const next = () => {
    props.setNextStep(
      props.currentStep.next?.find((s) => s.name === 'Scopes Selection')
    );
  };

  const validateName = useCallback((name: string): boolean => {
    return name?.length >= 4;
  }, []);

  return (
    <Stack sx={{ height: '100%' }} alignItems='center' justifyContent='center'>
      <div>
        <Typography variant='h3' sx={{ mb: '24px', textAlign: 'center' }}>
          Please provide a name for this role
        </Typography>
        <OutlinedInput
          data-testid='role-name-input'
          autoFocus
          sx={{
            width: '655px',
            padding: '8px',
          }}
          placeholder='Use a descriptive name, such as "Restore Only"'
          onChange={(e) =>
            props.setWizardState((state) => ({
              ...state,
              name: e.target.value,
            }))
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter' && validateName(props.wizardState.name)) {
              next();
            }
          }}
          value={props.wizardState.name}
          endAdornment={
            <Button
              data-testid='continue-button'
              sx={{ width: '200px' }}
              variant='contained'
              disabled={!validateName(props.wizardState.name)}
              onClick={next}
            >
              Continue
              <i className='material-symbols-arrow-right-alt ml-[8px]' />
            </Button>
          }
        />
      </div>
    </Stack>
  );
};
