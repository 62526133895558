import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';

import { useDAL } from '@/data/dal';

import { EmptySelect } from '../regions/emptySelect';
import SelectPlaceholder from '../selectPlaceholder';

export const InstanceProfileSelect = ({
  accountId,
  regionName,
  instanceProfile,
  onChange,
}: {
  accountId?: string;
  regionName?: string;
  instanceProfile?: string;
  onChange: (instanceProfile?: string, isUserChange?: boolean) => void;
}) => {
  if (!accountId || !regionName) {
    return <EmptySelect title='Instance profile' />;
  }

  return (
    <InnerInstanceProfileSelect
      accountId={accountId}
      regionName={regionName}
      instanceProfile={instanceProfile}
      onChange={onChange}
    />
  );
};

const InnerInstanceProfileSelect = ({
  accountId,
  regionName,
  instanceProfile,
  onChange,
}: {
  accountId: string;
  regionName: string;
  instanceProfile?: string;
  onChange: (instanceProfile?: string, isUserChange?: boolean) => void;
}) => {
  const dal = useDAL();
  const { body, isLoading } = dal.restore.instanceProfiles.list(
    accountId,
    regionName
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (
      instanceProfile &&
      !body?.instanceProfileNames?.includes(instanceProfile)
    ) {
      onChange(undefined);
    }
  }, [body?.instanceProfileNames, isLoading, onChange, instanceProfile]);

  if (!body?.instanceProfileNames?.length) {
    return <EmptySelect title='Instance profile' />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>Instance profile</FormLabel>
      <Select
        size='small'
        value={instanceProfile || ''}
        displayEmpty
        renderValue={(value: string) => {
          if (!value) {
            return <SelectPlaceholder />;
          }
          return value;
        }}
        onChange={(event) => onChange(event.target.value, !!event)}
      >
        <MenuItem key={'select'} value={undefined}>
          <Typography
            sx={{ alignSelf: 'center' }}
            color='var(--mui-palette-text-gray1)'
          >
            Select
          </Typography>
        </MenuItem>
        {body.instanceProfileNames.map((profileName) => (
          <MenuItem key={profileName} value={profileName}>
            {profileName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
