import type { User } from '@repo/api-gw-sdk';

import { Wizard } from '@/components/wizard/Wizard';

import { UpsertUserFlow, type UpsertUserState } from './upsertUserFlow';

export interface UpsertUserPanelProps {
  entity: User;
  onSave: (user: UpsertUserState) => void;
  onAbort: () => void;
}

export function UpsertUserPanel(props: UpsertUserPanelProps) {
  const save = (state: UpsertUserState) => {
    props.onSave(state);
    return true;
  };

  return (
    <Wizard<UpsertUserState>
      onAbort={props.onAbort}
      onFinish={save}
      flow={[UpsertUserFlow]}
      initialState={{ ...props.entity, email: props.entity.email || '' }}
      title={props.entity.id ? 'Edit Permissions' : 'Invite User'}
    />
  );
}
