import {
  Box,
  CardContent,
  FormControl,
  FormLabel,
  capitalize,
  MenuItem,
  Select,
} from '@mui/material';
import { useState } from 'react';

import { SidePanelWrapper } from '@/components/layout/sidePanel/sidePanelWrapper';
import { useUser } from '@/contexts/useUser';
import { useWorkspace } from '@/contexts/useWorkspace';

import { CreateAWSAccountPanel } from './cloudAccountAWSPanel';
import CreateAzureAccountPanel from './cloudAccountAzurePanel';
import type { AccountType } from './useCreateAccount';

export const CreateAccountPanel = ({
  accountType,
  onCreated,
}: {
  accountType: AccountType;
  onCreated: () => void;
}) => {
  const { rightPanel } = useWorkspace();
  const [cloudProvider, setCloudProvider] = useState('AWS');
  const [loading, setLoading] = useState(false);
  const { user } = useUser();

  const { setIsOpen } = rightPanel;
  const close = () => setIsOpen(false);

  return (
    <SidePanelWrapper
      title={`Connect ${capitalize(accountType)} Account`}
      close={close}
    >
      <CardContent>
        {user?.eonAccount?.azureSubscriptionId && (
          <Box className='flex flex-col justify-center mx-[20px] my-[30px] gap-[8px]'>
            <FormControl size='small'>
              <FormLabel>Cloud Provider</FormLabel>
              <Select
                value={cloudProvider}
                disabled={loading}
                onChange={(event) => setCloudProvider(event.target.value)}
                sx={{
                  height: '48px',
                  mb: '12px',
                }}
              >
                <MenuItem value='AWS'>AWS</MenuItem>
                <MenuItem value='Azure'>Azure</MenuItem>
                <MenuItem value='GCP' disabled>
                  GCP
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
        {cloudProvider === 'AWS' && (
          <CreateAWSAccountPanel
            accountType={accountType}
            onCreated={onCreated}
            setLoading={setLoading}
          />
        )}
        {cloudProvider === 'Azure' && (
          <CreateAzureAccountPanel
            accountType={accountType}
            onCreated={onCreated}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </CardContent>
    </SidePanelWrapper>
  );
};
