import type { Integration } from '@repo/api-gw-sdk';

import type { Step } from '@/components/wizard/Step';

import { IntegrationNameSelection } from './integrationNameSelection';
import { IntegrationScopesSelection } from './integrationScopesSelection';
import { IntegrationTokenReveal } from './integrationTokenReveal';

export type UpsertIntegrationState = Integration & {
  clientSecret?: string;
  clientId?: string;
};

export const UpsertIntegrationFlow: Step<UpsertIntegrationState> = {
  name: 'Name Selection',
  Component: IntegrationNameSelection,
  next: [
    {
      name: 'Scopes Selection',
      Component: IntegrationScopesSelection,
      next: [
        {
          name: 'Token Reveal',
          Component: IntegrationTokenReveal,
          next: [],
        },
      ],
    },
  ],
};
