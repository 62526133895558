import { Box } from '@mui/material';
import { type ControlRules } from '@repo/api-gw-sdk';
import classNames from 'classnames';

function NumberInput({
  number,
  rules,
  onChange,
}: {
  number: number;
  rules: ControlRules;
  onChange: (rules: ControlRules) => void;
}) {
  // preserve these icons:
  // material-symbols-counter-2-outline
  // material-symbols-counter-3-outline
  // material-symbols-counter-4-outline
  return (
    <Box
      component={'span'}
      sx={{
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '8px',
        width: 32,
        height: 32,
        fontSize: 20,
        cursor: 'pointer',
        color: 'var(--mui-palette-primary-main)',
        '&:hover': {
          background: 'var(--mui-palette-primary-dark)',
          color: 'var(--mui-palette-primary-main)',
        },
        '&.active': {
          background: 'var(--mui-palette-primary-main)',
          color: 'var(--mui-palette-primary-contrastText)',
        },
      }}
      className={classNames({
        active: rules.numberOfCopies?.minCopies === number,
      })}
      onClick={() =>
        onChange({ numberOfCopies: { enabled: true, minCopies: number } })
      }
    >
      <i className={`material-symbols-counter-${number}-outline`} />
    </Box>
  );
}

export function NumberOfCopiesEditor({
  rules,
  onChange,
}: {
  rules: ControlRules;
  onChange: (rules: ControlRules) => void;
}) {
  return (
    <>
      <NumberInput number={2} rules={rules} onChange={onChange} />
      <NumberInput number={3} rules={rules} onChange={onChange} />
      <NumberInput number={4} rules={rules} onChange={onChange} />
    </>
  );
}
