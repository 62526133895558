import { Divider, Stack, Tooltip, Typography } from '@mui/material';
import type { DataAccessRule, Role } from '@repo/api-gw-sdk';
import React from 'react';

import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import { useRoles } from '@/contexts/useRoles';

import { DataAccessRuleTooltip } from './dataAccessRuleTooltip';
import { PermissionIcon } from './permissionIcon';
import { PermissionOptionMenu } from './permissionOptionMenu';
import type { NamedPermissionDescriptor } from './types';
import { getAssignedDataAccessRule } from './utils';

export const RolePermissions = ({
  role,
  editMode,
  onChange,
  onConditional,
  hideUnauthorizedPermissions,
  hidePermissionGroups,
}:
  | {
      role: Role;
      hideUnauthorizedPermissions?: boolean;
      hidePermissionGroups?: boolean;
      editMode?: false;
      onChange?: undefined;
      onConditional?: undefined;
    }
  | {
      role: Role;
      onChange: (permission: string, value: boolean) => void;
      onConditional: (
        permissionName: string,
        dataAccessRule: DataAccessRule | undefined
      ) => void;
      editMode: true;

      hideUnauthorizedPermissions?: false;
      hidePermissionGroups?: false;
    }) => {
  const { permissions, isAuthorized } = useRoles();
  const { dataAccessRoles } = useFeatureFlags();

  const permissionsByCategory = Object.entries(permissions).reduce<
    Record<string, NamedPermissionDescriptor[]>
  >((agg, [permission, value]) => {
    agg[value.category] = agg[value.category] || [];
    agg[value.category].push({ name: permission, ...value });
    return agg;
  }, {});

  return (
    <Stack direction='row' className='w-full' justifyContent='space-evenly'>
      {Object.entries(permissionsByCategory)
        .filter(([category, permissions]) => {
          if (role.isProjectRole && category === 'Account') {
            return false;
          }

          if (
            !role.isBuiltInRole &&
            !role.isProjectRole &&
            category !== 'Account'
          ) {
            return false;
          }

          if (hideUnauthorizedPermissions) {
            return permissions.some((x) =>
              x.scopes.every((x) => isAuthorized(x, role))
            );
          }

          return true;
        })
        .map(([category, permissions], index, arr) => {
          const groupedPermissions = permissions.reduce<
            Record<string, typeof permissions>
          >((acc, perm) => {
            acc[perm.group || ''] = acc[perm.group || ''] || [];
            acc[perm.group || ''].push(perm);
            return acc;
          }, {});

          return (
            <React.Fragment key={category}>
              <Stack className='w-full'>
                <Typography
                  className='font-semibold cursor-default'
                  sx={{
                    margin: '12px 0 24px',
                  }}
                >
                  {category}
                </Typography>
                <Stack>
                  {Object.entries(groupedPermissions).map(
                    ([group, permissions]) => {
                      return (
                        <React.Fragment key={category + group}>
                          {group && !hidePermissionGroups && (
                            <Typography
                              className='mt-[24px] mb-[24px] cursor-default uppercase'
                              variant='body2'
                              sx={{
                                fontSize: '12px',
                              }}
                            >
                              {group}
                            </Typography>
                          )}
                          {permissions.map((permission) => {
                            const isPermissionAuthorized =
                              permission.scopes.every((x) =>
                                isAuthorized(x, role)
                              );

                            if (
                              hideUnauthorizedPermissions &&
                              !isPermissionAuthorized
                            ) {
                              return null;
                            }

                            const assignedDataAccessRule =
                              getAssignedDataAccessRule(
                                dataAccessRoles,
                                permission,
                                role
                              );

                            return (
                              <Stack
                                data-testid='role-permission'
                                direction='row'
                                gap='12px'
                                justifyContent='space-between'
                                key={permission.name}
                                className='pb-[8px]'
                                sx={{
                                  '&:hover i': {
                                    display: 'block !important',
                                  },
                                }}
                              >
                                <Stack
                                  direction='row'
                                  gap='12px'
                                  className='mt-[8px]'
                                >
                                  <Typography className='cursor-default'>
                                    {permission.name}
                                  </Typography>
                                  {!hidePermissionGroups &&
                                    permission.description && (
                                      <Tooltip
                                        className='hidden'
                                        title={permission.description}
                                        slotProps={{
                                          tooltip: {
                                            sx: {
                                              padding: '16px',
                                            },
                                          },
                                        }}
                                      >
                                        <i className=' material-symbols-info-outline h-[20px] w-[20px] p-[2px]' />
                                      </Tooltip>
                                    )}
                                </Stack>
                                <Stack direction='row' gap='20px'>
                                  {!hideUnauthorizedPermissions &&
                                    assignedDataAccessRule && (
                                      <DataAccessRuleTooltip
                                        dataAccessRule={assignedDataAccessRule}
                                      />
                                    )}
                                  {editMode && (
                                    <PermissionOptionMenu
                                      role={role}
                                      permission={permission}
                                      isPermissionAuthorized={
                                        isPermissionAuthorized
                                      }
                                      onBlocked={() =>
                                        onChange(permission.name, false)
                                      }
                                      onAllowed={() =>
                                        onChange(permission.name, true)
                                      }
                                      createDataAccessRule={() =>
                                        onConditional(
                                          permission.name,
                                          undefined
                                        )
                                      }
                                      assignDataAccessRule={(dataAccessRule) =>
                                        onConditional(
                                          permission.name,
                                          dataAccessRule
                                        )
                                      }
                                      icon={
                                        <PermissionIcon
                                          editMode
                                          permission={permission}
                                          role={role}
                                          isPermissionAuthorized={
                                            isPermissionAuthorized
                                          }
                                        />
                                      }
                                    />
                                  )}
                                  {!editMode && (
                                    <PermissionIcon
                                      permission={permission}
                                      role={role}
                                      isPermissionAuthorized={
                                        isPermissionAuthorized
                                      }
                                    />
                                  )}
                                </Stack>
                              </Stack>
                            );
                          })}
                        </React.Fragment>
                      );
                    }
                  )}
                </Stack>
              </Stack>
              {index < arr.length - 1 && (
                <Divider
                  orientation='vertical'
                  flexItem
                  className='mx-[40px]'
                />
              )}
            </React.Fragment>
          );
        })}
    </Stack>
  );
};
