import { List, ListItemButton, Radio, Typography } from '@mui/material';
import type { AccountCloudEnum } from '@repo/api-gw-sdk';

import { Loader } from '@/components/layout/loading';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { StepContainer } from '../../wizard/StepContainer';

export type SelectTargetAccountProps<T> = StepProps<
  T & {
    restoreAccountId?: string;
    restoreAccountCloudProvider?: AccountCloudEnum;
  }
>;

export const SelectTargetAccount = <T,>(props: SelectTargetAccountProps<T>) => {
  const dal = useDAL();
  const { body } = dal.cloudAccounts.restore.list();

  if (!body) {
    return <Loader />;
  }

  return (
    <StepContainer
      stepperLabels={props.stepperLabels}
      stepperIndex={0}
      onBackClick={props.back}
      canGoNext={() => !!props.wizardState.restoreAccountId}
      onNextClick={() => props.setNextStep(props.currentStep.next?.[0])}
    >
      <Typography component={'span'}>
        Select an account to restore to:
      </Typography>
      <List className='mt-[20px] mb-[10px]'>
        {body.accounts
          .filter(
            (account) =>
              !props.wizardState.restoreAccountCloudProvider ||
              account.cloud === props.wizardState.restoreAccountCloudProvider
          )
          .map((account) => {
            const isChecked = props.wizardState.restoreAccountId === account.id;
            return (
              <ListItemButton
                key={account.id}
                sx={{
                  border: isChecked
                    ? '2px solid var(--mui-palette-primary-main)'
                    : '1px solid var(--mui-palette-divider)',
                  marginBottom: '8px',
                }}
                onClick={() =>
                  props.setWizardState((state) => ({
                    ...state,
                    restoreAccountId: account.id,
                  }))
                }
                className='flex'
              >
                <Radio className='p-0' checked={isChecked} value={account.id} />
                <Typography className='flex-grow'>{account.name}</Typography>
                {account.providerAccountId !== account.name && (
                  <Typography component={'span'}>
                    {account.providerAccountId}
                  </Typography>
                )}
              </ListItemButton>
            );
          })}
      </List>
    </StepContainer>
  );
};
