import { Stack } from '@mui/material';
import {
  ResourceType,
  type BackupVault,
  type InventoryResource,
  type Snapshot,
  type SnapshotPropertiesVolumePropertiesInner,
} from '@repo/api-gw-sdk';
import React, { useEffect, useState } from 'react';

import { useWorkspace } from '@/contexts/useWorkspace';
import { isDateValid } from '@/utils/dateTime';

import { Ec2SnapshotRow, type RestoreActionType } from './ec2SnapshotRow';
import { SnapshotDrawerHeader } from './snapshotDrawerHeader';
import { SnapshotRow } from './snapshotRow';

import { Panels } from '../../panels';

interface RestoreSnapshotBarProps {
  resourceId: string;
  vaults: BackupVault[];
  snapshots: Snapshot[];
  selectedDate?: Date;
  entity: InventoryResource;
  onClose: () => void;
}

const RestorePanels: Partial<
  Record<
    ResourceType,
    'RestoreS3Wizard' | 'RestoreRDSWizard' | 'RestoreMongoAtlasWizard'
  >
> = {
  [ResourceType.S3]: Panels.RestoreS3Wizard,
  [ResourceType.Rds]: Panels.RestoreRDSWizard,
  [ResourceType.AtlasCluster]: Panels.RestoreMongoAtlasWizard,
};

export const RestoreSnapshotDrawer = (props: RestoreSnapshotBarProps) => {
  const { resourceId, vaults, snapshots, selectedDate, entity, onClose } =
    props;
  const { rightPanel } = useWorkspace();
  const { setComponent } = rightPanel;
  const [selectedVault, setSelectedVault] = useState<BackupVault | undefined>();
  const [filteredVaults, setFilteredVaults] = useState<BackupVault[]>([]);
  const [selectedDateSnapshots, setSelectedDateSnapshots] = useState<
    Snapshot[]
  >([]);
  const [selectedSnapshot, setSelectedSnapshot] = React.useState<string>('');

  const restorePanel = RestorePanels[entity.resourceType];

  useEffect(() => {
    const dateSnapshots = selectedDate
      ? (snapshots ?? [])
          .filter((e) => {
            const date = isDateValid(e.pointInTime)
              ? e.pointInTime
              : e.createTime;

            return date.toDateString() === selectedDate?.toDateString();
          })
          .sort((a, b) => b.createTime.getTime() - a.createTime.getTime())
      : [];
    setSelectedDateSnapshots(dateSnapshots);
    setSelectedSnapshot(dateSnapshots[0]?.id || '');
  }, [selectedDate, snapshots]);

  useEffect(() => {
    const filtered = vaults?.filter((v) => {
      const vaultSnapshots = selectedDateSnapshots.filter(
        (s) => s.vaultId === v.id
      );
      return vaultSnapshots.length > 0;
    });
    setFilteredVaults(filtered);
    setSelectedVault(filtered?.[0]);
  }, [vaults, selectedDateSnapshots]);

  const snapshotsByVault = selectedDateSnapshots.filter(
    (snap) => snap.vaultId === selectedVault?.id
  );

  if (snapshotsByVault.length <= 0 || !selectedVault) {
    return null;
  }

  const getOnSelect = (snapId: string) =>
    snapshotsByVault.length > 1 ? () => setSelectedSnapshot(snapId) : undefined;

  return (
    <Stack
      className='w-full'
      direction='column'
      boxShadow='0px 10px 30px 0px rgba(0, 0, 0, 0.2)'
    >
      <Stack className='w-full' direction='column'>
        <SnapshotDrawerHeader
          selectedDate={selectedDate}
          vaults={filteredVaults}
          setSelectedVault={setSelectedVault}
          onClose={onClose}
        />
        <Stack direction='column' alignItems='center' width='100%'>
          {snapshotsByVault.map((snap) => {
            if (restorePanel) {
              return (
                <SnapshotRow
                  key={snap.id}
                  snap={snap}
                  vault={selectedVault}
                  isSelected={selectedSnapshot === snap.id}
                  onSelect={getOnSelect(snap.id)}
                  resource={entity}
                  onRestore={() => {
                    setComponent({
                      panel: restorePanel,
                      props: {
                        snapshotId: snap.id,
                        resourceId,
                      },
                    });
                  }}
                />
              );
            }

            if (entity.resourceType === ResourceType.Ec2) {
              return (
                <Ec2SnapshotRow
                  resource={entity}
                  key={snap.id}
                  isSelected={selectedSnapshot === snap.id}
                  snap={snap}
                  vault={selectedVault}
                  onSelect={getOnSelect(snap.id)}
                  onRestore={(
                    vols: SnapshotPropertiesVolumePropertiesInner[],
                    actionType: RestoreActionType
                  ) => {
                    if (vols.length === 0) {
                      return;
                    }
                    return actionType === 'full-instance'
                      ? setComponent({
                          panel: Panels.RestoreEc2InstanceWizard,
                          props: {
                            snapshotId: snap.id,
                            resourceId,
                            volumes: vols,
                            sourceRegion: vols[0].region,
                            resourceProperties: snap.resourceProperties,
                          },
                        })
                      : setComponent({
                          panel: Panels.RestoreVolumeWizard,
                          props: {
                            snapshotId: snap.id,
                            instanceId: resourceId,
                            providerVolumeId: vols[0].volumeName,
                            volumeRegion: vols[0].region,
                            actionType,
                          },
                        });
                  }}
                />
              );
            }
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
