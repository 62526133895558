import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

import SelectPlaceholder from '../selectPlaceholder';

const title = 'Subnet*';

export const AWSSubnetSelect = ({
  accountId,
  regionName,
  subnetId,
  onChange,
}: {
  accountId?: string;
  regionName?: string;
  subnetId?: string;
  onChange: (subnetId?: string, isUserChange?: boolean) => void;
}) => {
  if (!accountId || !regionName) {
    return <EmptySelect title={title} />;
  }

  return (
    <InnerAWSSubnetSelect
      accountId={accountId}
      regionName={regionName}
      subnetId={subnetId}
      onChange={onChange}
    />
  );
};

const InnerAWSSubnetSelect = ({
  accountId,
  regionName,
  subnetId,
  onChange,
}: {
  accountId: string;
  regionName: string;
  subnetId?: string;
  onChange: (subnetId?: string, event?: boolean) => void;
}) => {
  const dal = useDAL();
  const { body, isLoading } = dal.restore.listAwsSubnets.list(
    accountId,
    regionName
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (subnetId && !body?.subnetIds?.includes(subnetId)) {
      onChange(undefined);
    }
  }, [body?.subnetIds, isLoading, onChange, subnetId]);

  if (!body?.subnetIds?.length) {
    return <EmptySelect title={title} />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>{title}</FormLabel>
      <Select
        size='small'
        value={subnetId || ''}
        onChange={(event) => onChange(event.target.value, !!event)}
        displayEmpty
        renderValue={(value: string) => {
          if (!value) {
            return <SelectPlaceholder />;
          }
          return value;
        }}
      >
        {body.subnetIds.map((subnetId) => (
          <MenuItem key={subnetId} value={subnetId}>
            {subnetId}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
