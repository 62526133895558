import type { Role } from '@repo/api-gw-sdk';

import type { Step } from '@/components/wizard/Step';

import { CustomRoleNameSelection } from './customRoleNameSelection';
import { CustomRoleScopesSelection } from './customRoleScopesSelection';

export type UpsertCustomRoleState = Role;

export const UpsertCustomRoleFlow: Step<UpsertCustomRoleState> = {
  name: 'Name Selection',
  Component: CustomRoleNameSelection,
  next: [
    {
      name: 'Scopes Selection',
      Component: CustomRoleScopesSelection,
      next: [],
    },
  ],
};
