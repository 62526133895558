import { ListItem, styled } from '@mui/material';

interface OptionListItemProps {
  isChecked?: boolean;
}

export const OptionListItem = styled(ListItem)<OptionListItemProps>(({
  isChecked = true,
}) => {
  const border = isChecked
    ? '2px solid var(--mui-palette-primary-main)'
    : '1px solid var(--mui-palette-divider)';
  return {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    alignItems: 'flex-start',
    padding: '16px',
    borderRadius: 'var(--mui-shape-borderRadius)',
    border,
    marginBottom: '8px',
    gap: 0,

    '.content': {
      height: 0,
      overflow: 'hidden',

      '> :first-child': {
        marginTop: '20px',
      },

      '&.expanded': {
        height: 'auto',
        cursor: 'default',
      },
    },
  };
});
