import { AppBar, styled, Toolbar } from '@mui/material';

export const ActionsBar = styled(AppBar)({
  '&': {
    minHeight: '48px',
    backgroundColor: 'transparent',
    marginBottom: '10px',
    boxShadow: 'none',
    borderBottom: '1px solid var(--border-color)',
  },
});

export const ActionsToolBar = styled(Toolbar)({
  '@media (min-width: 600px)': {
    minHeight: '48px',
  },
});
