import { AccountCloudEnum } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import { MongoAtlasConfiguration } from './steps/mongoAtlasConfiguration';
import { SelectTargetAccount } from './steps/selectTargetAccount';

import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface restoreMongoAtlasState {
  resourceId: string;
  snapshotId: string;
  resourceName?: string;
  restoreAccountCloudProvider?: AccountCloudEnum;
  restoreAccountId?: string;
  projectId?: string;
  tags?: Record<string, string>;
  keepOriginalTags?: boolean;
}

const restoreMongoAtlasFlow: Step<restoreMongoAtlasState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccount,
  next: [
    {
      name: 'MongoAtlasConfiguration',
      Component: MongoAtlasConfiguration,
    },
  ],
};

export const RestoreMongoAtlasWizard = ({
  resourceId,
  snapshotId,
}: {
  resourceId: string;
  snapshotId: string;
  sourceRegion?: string;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: { id: resourceId, onClose: () => setIsOpen(false) },
    });

    return true;
  };

  return (
    <Wizard<restoreMongoAtlasState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreMongoAtlasFlow]}
      initialState={{
        snapshotId,
        resourceId,
        restoreAccountCloudProvider: AccountCloudEnum.MongoAtlas,
        keepOriginalTags: true,
      }}
      title={`Restore Mongo Atlas Cluster`}
      stepperLabels={['Restore Account', 'Configuration']}
    />
  );
};
