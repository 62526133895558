import { Divider, Radio, Stack, Typography } from '@mui/material';
import type {
  InventoryResource,
  Snapshot,
  SnapshotPropertiesVolumePropertiesInner,
} from '@repo/api-gw-sdk';
import { useState } from 'react';

import { Icon } from '@/components/shared/icon';
import type { BackupVault } from '@/data/vaults/backupVault';

import type { RestoreActionType } from './ec2SnapshotRow';
import { SnapshotActionsGroup } from './snapshotActionsGroup';

interface SnapshotDetailsBodyProps {
  resource: InventoryResource;
  snapshot: Snapshot;
  vault?: BackupVault;
  onRestore: (
    volumes: SnapshotPropertiesVolumePropertiesInner[],
    actionType: RestoreActionType
  ) => void;
  onConvertSnapshot?: (
    volumes: SnapshotPropertiesVolumePropertiesInner[]
  ) => void;
}

export const SnapshotDetailsBody = (props: SnapshotDetailsBodyProps) => {
  const { resource, snapshot, vault } = props;
  const [fullInstanceSelected, setFullInstanceSelected] = useState(true);
  const volumes = snapshot?.resourceProperties?.volumeProperties || [];
  const [selectedVolume, setSelectedVolume] = useState(volumes[0] || null);

  const onRestore = () => {
    if (fullInstanceSelected) {
      props.onRestore(volumes, 'full-instance');
      return;
    }

    if (selectedVolume) {
      props.onRestore([selectedVolume], 'volume');
    }
  };

  const onConvertSnapshot = () => {
    const relevantVolumes = fullInstanceSelected
      ? volumes
      : selectedVolume
        ? [selectedVolume]
        : [];
    props.onRestore(relevantVolumes, 'snapshot');
  };

  return (
    <Stack width='100%' height='100%' direction='column'>
      <Stack
        width='100%'
        direction='row'
        gap='40px'
        marginBottom='24px'
        marginLeft='36px'
      >
        <Stack direction='column' gap='16px'>
          <Typography marginBottom='8px' variant='body2'>
            What do you want to restore?
          </Typography>
          <Stack
            className='cursor-pointer'
            direction='row'
            alignItems='center'
            gap='12px'
            onClick={() => setFullInstanceSelected(true)}
          >
            <Radio
              // className='w-[24px] h-[24px]'
              size='small'
              checked={fullInstanceSelected}
            />
            <Typography variant='body2'>Full instance</Typography>
          </Stack>
          <Stack
            className='cursor-pointer'
            direction='row'
            alignItems='center'
            gap='12px'
            onClick={() => setFullInstanceSelected(false)}
          >
            <Radio size='small' checked={!fullInstanceSelected} />
            <Typography variant='body2'>Specific volume</Typography>
          </Stack>
        </Stack>
        <Divider orientation='vertical' flexItem />
        <Stack direction='column' gap='16px'>
          <Typography marginBottom='8px' variant='body2'>
            {fullInstanceSelected
              ? 'These volumes will be restored'
              : 'Choose a volume to restore'}
          </Typography>
          {volumes.map((volume) => (
            <Stack
              key={volume.volumeId}
              className='cursor-pointer'
              direction='row'
              alignItems='center'
              gap='12px'
              onClick={() => setSelectedVolume(volume)}
            >
              {fullInstanceSelected ? (
                <Icon
                  className='p-[2px]'
                  iconClass='material-symbols-check w-[20px] h-[20px]'
                />
              ) : (
                <Radio
                  size='small'
                  checked={volume.volumeId === selectedVolume.volumeId}
                />
              )}
              <Typography variant='body2'>{volume.volumeName}</Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <SnapshotActionsGroup
        snapshot={snapshot}
        vault={vault}
        onRestore={onRestore}
        resource={resource}
        onConvertSnapshot={fullInstanceSelected ? undefined : onConvertSnapshot}
      />
    </Stack>
  );
};
