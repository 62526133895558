import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

import SelectPlaceholder from '../selectPlaceholder';

export const SecurityGroupSelect = ({
  accountId,
  regionName,
  securityGroupId,
  onChange,
  vpc,
  isVpcMandatory,
  subnetId,
  isSubnetIdMandatory,
  isMandatoryField,
}: {
  accountId?: string;
  regionName?: string;
  securityGroupId?: string;
  vpc?: string;
  isVpcMandatory?: boolean;
  subnetId?: string;
  isSubnetIdMandatory?: boolean;
  onChange: (securityGroupId?: string, isUserChange?: boolean) => void;
  isMandatoryField?: boolean;
}) => {
  const title = `Security group${isMandatoryField ? '*' : ''}`;
  if (
    !accountId ||
    !regionName ||
    (isVpcMandatory && !vpc) ||
    (isSubnetIdMandatory && !subnetId)
  ) {
    return <EmptySelect title={title} />;
  }

  return (
    <InnerSecurityGroupSelect
      vpc={vpc}
      subnetId={subnetId}
      accountId={accountId}
      regionName={regionName}
      securityGroupId={securityGroupId}
      onChange={onChange}
      isMandatoryField={isMandatoryField}
    />
  );
};

const InnerSecurityGroupSelect = ({
  vpc,
  subnetId,
  accountId,
  regionName,
  securityGroupId,
  onChange,
  isMandatoryField,
}: {
  accountId: string;
  regionName: string;
  vpc?: string;
  subnetId?: string;
  securityGroupId?: string;
  onChange: (securityGroupId?: string, isUserChange?: boolean) => void;
  isMandatoryField?: boolean;
}) => {
  const dal = useDAL();
  const { body, isLoading } = dal.restore.securityGroups.list(
    accountId,
    regionName,
    vpc,
    subnetId
  );

  const title = `Security group${isMandatoryField ? '*' : ''}`;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (
      securityGroupId &&
      !body?.securityGroups?.some((sg) => sg.id === securityGroupId)
    ) {
      onChange(undefined);
    }
  }, [body?.securityGroups, isLoading, onChange, securityGroupId]);

  if (!body?.securityGroups.length) {
    return <EmptySelect title={title} />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>{title}</FormLabel>
      <Select
        size='small'
        value={securityGroupId || ''}
        onChange={(event) => onChange(event.target.value, !!event)}
        displayEmpty
        renderValue={(value) => {
          if (!value) {
            return <SelectPlaceholder />;
          }
          return body.securityGroups.find((group) => group.id === value)?.name;
        }}
      >
        <MenuItem key={'select'} value={undefined}>
          <Typography
            sx={{ alignSelf: 'center' }}
            color='var(--mui-palette-text-gray1)'
          >
            Select
          </Typography>
        </MenuItem>
        {body.securityGroups.map((value) => (
          <MenuItem key={value.id} value={value.id}>
            {value.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
