import { Divider, Stack, Tooltip, Typography } from '@mui/material';
import type { DataAccessRule } from '@repo/api-gw-sdk';
import React from 'react';

import OptionMenu from '@/@core/components/option-menu';

import { dataAccessConditionSupportedProperties } from './utils';

import ConditionViewer from '../conditions/ConditionViewer';

export const DataAccessRuleComponent = ({
  dataAccessRule,
  showActions,
  onEdit,
  onDelete,
}:
  | {
      dataAccessRule: DataAccessRule;
      showActions: true;
      onEdit: () => void;
      onDelete: () => void;
    }
  | {
      dataAccessRule: DataAccessRule;
      showActions?: false;
      onEdit?: undefined;
      onDelete?: undefined;
    }) => {
  return (
    <Stack
      sx={{
        border: showActions ? '1px solid var(--mui-palette-divider)' : 'none',
        borderRadius: '16px',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        className='my-[16px] mx-[24px]'
      >
        <Typography className='font-semibold'>{dataAccessRule.name}</Typography>
        {showActions && (
          <OptionMenu
            iconClassName='text-textPrimary'
            icon='material-symbols-more-horiz'
            options={[
              {
                text: 'Edit',
                menuItemProps: {
                  onClick: onEdit,
                },
              },
              {
                text:
                  dataAccessRule.scopes.length > 0 ? (
                    <Tooltip
                      title={
                        'This can’t be deleted because it’s applied to a permission.'
                      }
                    >
                      <Typography
                        color='var(--mui-palette-text-disabled)'
                        className='bg-transparent hover:bg-transparent'
                      >
                        {'Delete'}
                      </Typography>
                    </Tooltip>
                  ) : (
                    'Delete'
                  ),
                menuItemProps: {
                  sx: {
                    ...(dataAccessRule.scopes.length > 0
                      ? {
                          cursor: 'default',

                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }
                      : {}),
                  },
                  onClick:
                    dataAccessRule.scopes.length === 0 ? onDelete : undefined,
                },
              },
            ]}
          />
        )}
      </Stack>
      <Divider />
      <Stack className='m-[24px]' gap='24px'>
        <Stack direction='row' alignItems='center' gap='6px'>
          <Typography
            className='font-semibold'
            color={
              dataAccessRule.isInclusive
                ? 'var(--mui-palette-success-main)'
                : 'var(--mui-palette-error-main)'
            }
          >
            {dataAccessRule.isInclusive ? 'Allow' : 'Block'}
          </Typography>
          <Typography>{`access only to resources where:`}</Typography>
        </Stack>
        <ConditionViewer
          condition={dataAccessRule.condition}
          supportProperties={dataAccessConditionSupportedProperties.flat()}
        />
      </Stack>
    </Stack>
  );
};

export const DataAccessRuleTooltip = ({
  dataAccessRule,
}: {
  dataAccessRule: DataAccessRule;
}) => (
  <Tooltip
    slotProps={{
      tooltip: {
        sx: {
          padding: 0,
          color: 'var(--mui-palette-text-primary)',
          backgroundColor: 'var(--mui-palette-background-paper)',
          minWidth: '364px',
          boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.10)',
        },
      },
    }}
    title={
      <DataAccessRuleComponent
        dataAccessRule={dataAccessRule}
        showActions={false}
      />
    }
  >
    <Typography
      color='#EC6924'
      variant='subtitle1'
      sx={{
        maxWidth: '120px',
        marginTop: '8px',
        fontWeight: '600',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {dataAccessRule.name}
    </Typography>
  </Tooltip>
);
