import type { BackupPolicy } from '@repo/api-gw-sdk';

import { Wizard } from '@/components/wizard/Wizard';

import {
  EditBackupPolicyFlow,
  type BackupPolicyEditState,
} from './BackupPolicyCreationFlow';

export interface BackupPolicyPanelProps {
  entity: BackupPolicy;
  onSave: (policy: BackupPolicy) => void;
  onAbort: () => void;
}

export default function BackupPolicyPanel(props: BackupPolicyPanelProps) {
  const save = (state: BackupPolicyEditState) => {
    props.onSave({
      ...props.entity,
      name: state.name,
      condition: state.condition,
      schedules: state.schedules ?? [],
      include: state.include ?? [],
      exclude: state.exclude ?? [],
    });

    return true;
  };

  const validate = (state: BackupPolicyEditState) => {
    if (!state.name || state.name.trim() === '' || state.name.length < 4) {
      return 'Name is required';
    }

    if (!state.schedules || state.schedules.length === 0) {
      return 'At least one schedule is required';
    }

    for (const schedule of state.schedules) {
      if (!schedule.vaultId) {
        return 'All schedules must have a backup vault selected';
      }
    }
  };

  return (
    <Wizard<BackupPolicyEditState>
      onAbort={props.onAbort}
      onFinish={save}
      validate={validate}
      flow={[EditBackupPolicyFlow]}
      initialState={{
        id: props.entity.id,
        name: props.entity.name,
        condition: props.entity.condition,
        schedules: props.entity.schedules,
        include: props.entity.include,
        exclude: props.entity.exclude,
      }}
      title={props.entity.id ? 'Edit Backup Policy' : 'Create Backup Policy'}
      stepperLabels={['Condition', 'Resource list', 'Frequency and retention']}
    />
  );
}
