import { Box } from '@mui/material';

import { useWorkspace } from '@/contexts/useWorkspace';

import { SidePanelWrapper } from '../layout/sidePanel/sidePanelWrapper';
import { Icon } from '../shared/icon';

export const FiltersPanelWrapper = ({
  title,
  subtitle,
  onBack,
  children,
}: React.PropsWithChildren<{
  title: string;
  subtitle: string | undefined;
  onBack?: () => void;
}>) => {
  const { leftPanel } = useWorkspace();
  const { setIsOpen } = leftPanel;
  const closePanel = () => setIsOpen(false);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'var(--mui-palette-background-paper)'
            : 'rgb(255,255,255)',
        borderRadius: '16px',
        margin: '40px',
        height: 'calc(100% - 80px)',
      })}
    >
      <SidePanelWrapper
        title={title}
        subtitle={subtitle}
        close={closePanel}
        hideBorder
        icon={
          onBack && (
            <Icon
              iconClass={`material-symbols-chevron-left-rounded`}
              className='ml-[-10px]'
              onClick={onBack}
            />
          )
        }
      >
        {children}
      </SidePanelWrapper>
    </Box>
  );
};
