import { Select, Stack, styled } from '@mui/material';

export const ScheduleContainer = styled('div')(() => ({
  margin: '8px 0',
}));

export const Schedule = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: '16px',
  display: 'inline-flex',
}));

export const ScheduleSelect = styled(Select)(({ theme }) => ({
  '&::before, &::after': {
    border: 'none',
  },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
  },
  '& div.MuiSelect-select': {
    padding: '5px',
  },
  '& .MuiSelect-select .MuiTypography-root': {
    marginRight: '16px',
  },
  '& i': {
    marginLeft: '8px',
    right: 0,
    border: '1px solid green',
  },
  '& .MuiList-root .MuiMenuItem-root p': {
    fontWeight: '400',
  },
  '& .MuiTypography-root': {
    fontWeight: '600',
    color: theme.palette.mode === 'dark' ? 'white' : '#272742',
  },
}));
