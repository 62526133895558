import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  MenuItem,
  Radio,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';

import { OptionListItem } from '@/components/shared/optionListItem';
import { TransactionalTextField } from '@/components/shared/transactionalTextField';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';

import SelectPlaceholder from '../selectPlaceholder';

export interface RestoreBucketState {
  restoreBucketName?: string;
  restoreBucketNameOverride?: string;
  restoreBucketMode: 'select' | 'manual';
  restoreBucketSelectionPrefix?: string;
  restoreBucketManualPrefix?: string;
}

export const BucketSelection = <T extends RestoreBucketState>(
  props: StepProps<T & { restoreAccountId?: string }>
) => {
  const { setWizardState: setStepState } = props;
  const {
    restoreAccountId,
    restoreBucketMode,
    restoreBucketName,
    restoreBucketSelectionPrefix,
    restoreBucketManualPrefix,
    restoreBucketNameOverride,
  } = props.wizardState;
  const dal = useDAL();
  const { body: pref } = dal.preferences.getUserPref(
    PreferencesKey.RestoreBucket
  );
  const { body: s3BucketsResponse } = dal.restore.s3Buckets.list(
    restoreAccountId!
  );
  const setMode = (mode: 'select' | 'manual') =>
    setStepState((state) => ({
      ...state,
      restoreBucketMode: mode,
    }));

  useEffect(() => {
    if (pref?.value?.bucketName) {
      setStepState((state) => ({
        ...state,
        restoreBucketName: pref.value!.bucketName,
        restoreBucketNameOverride: pref.value!.bucketName,
      }));
    }
  }, [pref, setStepState]);

  const isSelectMode = restoreBucketMode === 'select';
  const isManualMode = restoreBucketMode === 'manual';

  return (
    <Box className='mx-[40px] my-[24px]'>
      <Box className='flex justify-between items-center'>
        <Typography className='font-semibold' variant='body1'>
          Choose a bucket to restore to*
        </Typography>
      </Box>
      <List className='mt-[20px]'>
        <OptionListItem
          className='pl-[24px]'
          isChecked={isSelectMode}
          onClick={() => {
            setMode('select');
          }}
        >
          <FormControlLabel
            checked={isSelectMode}
            onClick={() => {
              setMode('select');
            }}
            control={<Radio className='pr-[12px]' />}
            label='Select an existing S3 bucket'
          />
          <Collapse className='w-full pl-[36px] pr-[40px]' in={isSelectMode}>
            <Stack direction='row' gap='24px' className='mt-[20px] mb-[24px]'>
              <FormControl size='small' className='flex-1'>
                <FormLabel>Bucket name</FormLabel>
                <Select
                  displayEmpty
                  renderValue={(value: string) => {
                    if (!value) {
                      return <SelectPlaceholder />;
                    }
                    const bucket = s3BucketsResponse?.buckets?.find(
                      (x) => x.bucketName === value
                    );
                    if (!bucket) {
                      return <SelectPlaceholder />;
                    }
                    return <BucketRegionItem {...bucket} />;
                  }}
                  className='h-[58px]'
                  size='small'
                  value={
                    s3BucketsResponse?.buckets?.find(
                      (x) => x.bucketName === restoreBucketName
                    )?.bucketName || ''
                  }
                  onChange={(event) =>
                    setStepState((state) => ({
                      ...state,
                      restoreBucketName: event.target.value,
                    }))
                  }
                >
                  {(s3BucketsResponse?.buckets || []).map((bucket) => (
                    <MenuItem key={bucket.bucketName} value={bucket.bucketName}>
                      <BucketRegionItem {...bucket} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <BucketPrefix
                prefix={restoreBucketSelectionPrefix}
                onChange={(value) => {
                  setStepState((state) => ({
                    ...state,
                    restoreBucketSelectionPrefix: value,
                  }));
                }}
              />
            </Stack>
          </Collapse>
        </OptionListItem>
        <OptionListItem
          className='pl-[24px]'
          isChecked={isManualMode}
          onClick={() => setMode('manual')}
        >
          <FormControlLabel
            checked={isManualMode}
            onClick={() => setMode('manual')}
            control={<Radio className='pr-[12px]' />}
            label='Enter a Bucket name'
          />
          <Collapse className='w-full pl-[36px] pr-[40px]' in={isManualMode}>
            <Stack direction='row' gap='24px' className='mt-[20px] mb-[24px]'>
              <FormControl size='small' className='w-1/2'>
                <FormLabel>Bucket Name</FormLabel>
                <TransactionalTextField
                  initValue={restoreBucketNameOverride || ''}
                  onChange={(value) => {
                    setStepState((state) => ({
                      ...state,
                      restoreBucketNameOverride: value,
                    }));
                  }}
                />
              </FormControl>
              <BucketPrefix
                prefix={restoreBucketManualPrefix}
                onChange={(value) => {
                  setStepState((state) => ({
                    ...state,
                    restoreBucketManualPrefix: value,
                  }));
                }}
              />
            </Stack>
          </Collapse>
        </OptionListItem>
      </List>
    </Box>
  );
};

const BucketRegionItem = (props: {
  bucketName: string;
  regionName: string;
}) => {
  return (
    <Stack direction={'column'} className='py-[4px]'>
      <Typography>{props.bucketName}</Typography>
      <Typography variant='body2' className='mt-[4px]'>
        {props.regionName}
      </Typography>
    </Stack>
  );
};

const BucketPrefix = (props: {
  prefix?: string;
  onChange: (value: string) => void;
}) => {
  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>Prefix (optional)</FormLabel>
      <Stack height='100%' direction='column' justifyContent='space-between'>
        <TransactionalTextField
          initValue={props.prefix || ''}
          onChange={props.onChange}
        />
        <Typography paddingTop='8px' variant='body2'>
          Example: /Project/WordFiles/
        </Typography>
      </Stack>
    </FormControl>
  );
};
