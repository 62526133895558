import {
  Box,
  CardContent,
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';

import { SidePanelPropsTable } from '@/components/panels/instance/tabs/shared';
import { CircleImage } from '@/components/shared/circleImage';
import { TagsSection } from '@/components/tags/tagsSection';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { ApplicationsLogosWithAliases } from '@/data/inventory/data';

import { EonTagRemark } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import type { restoreMongoAtlasState } from '../restoreMongoAtlasWizard';

export const MongoAtlasConfiguration = (
  props: StepProps<restoreMongoAtlasState>
) => {
  const dal = useDAL();
  const { body: inventoryResourceBody } = dal.inventory.get(
    props.wizardState.resourceId
  );
  const { body: projectsResponse } = dal.restore.atlasProjects.list(
    props.wizardState.restoreAccountId!
  );

  useEffect(() => {
    if (!props.wizardState.projectId && projectsResponse?.projects) {
      props.setWizardState((state) => ({
        ...state,
        projectId: projectsResponse.projects[0]?.id,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsResponse]);

  return (
    <StepContainer
      navigationComponent={<EonTagRemark />}
      stepperLabels={props.stepperLabels}
      stepperIndex={props.stepperLabels.length - 1}
      nextButtonText='Restore'
      onBackClick={props.back}
      canGoNext={() => {
        if (!props.wizardState.projectId || !props.wizardState.resourceName) {
          return false;
        }

        return true;
      }}
      onNextClick={() => {
        const tags = props.wizardState.tags || {};
        tags['eon:restore'] = 'true';

        void dal.restore.atlasProjects
          .restore(props.wizardState.resourceId, props.wizardState.snapshotId, {
            tags,
            restoreAccountId: props.wizardState.restoreAccountId!,
            clusterName: props.wizardState.resourceName!,
            atlasProjectId: props.wizardState.projectId!,
          })
          .then(() => {
            props.abort();
            props.onSuccess('Restore job has started!');
          });
      }}
    >
      <Box className='mb-[24px]'>
        <Box className='w-full flex justify-between mt-[20px] gap-[36px]'>
          <FormControl size='small' className='flex-1'>
            <FormLabel>Project</FormLabel>
            <Select
              size='small'
              value={
                (projectsResponse?.projects && props.wizardState.projectId) ||
                ''
              }
              onChange={(event) =>
                props.setWizardState((state) => ({
                  ...state,
                  projectId: event.target.value,
                }))
              }
            >
              {(projectsResponse?.projects || []).map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <FormControl size='small' className='w-full mt-[20px]'>
          <FormLabel>Database name</FormLabel>
          <TextField
            size='small'
            value={props.wizardState.resourceName || ''}
            onChange={(event) =>
              props.setWizardState((state) => ({
                ...state,
                resourceName: event.target.value,
              }))
            }
          />
        </FormControl>
      </Box>
      <Divider />
      {inventoryResourceBody?.resource.databaseProperties?.engine && (
        <>
          <Box className='w-full my-[24px]'>
            <Typography variant='h6'>Database settings</Typography>
            <SidePanelPropsTable className='my-[24px]'>
              <tbody>
                <tr>
                  <td className='props-col'>Engine</td>
                  <td className='capitalize'>
                    <div className='flex items-center'>
                      <CircleImage
                        alt={
                          inventoryResourceBody.resource.databaseProperties
                            .engine
                        }
                        src={
                          ApplicationsLogosWithAliases[
                            inventoryResourceBody.resource.classifications
                              ?.appsDetails?.apps?.[0]?.name || ''
                          ]
                        }
                        className='mr-[8px]'
                      />
                      <Typography color='var(--mui-palette-text-secondary)'>
                        {
                          inventoryResourceBody.resource.databaseProperties
                            .engine
                        }
                      </Typography>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='props-col'>Engine version</td>
                  <td>
                    {
                      inventoryResourceBody.resource.databaseProperties
                        .engineVersion
                    }
                  </td>
                </tr>
              </tbody>
            </SidePanelPropsTable>
          </Box>
          <Divider />
        </>
      )}
      <CardContent className='px-0'>
        <TagsSection
          initialTags={{}}
          tags={props.wizardState.tags || {}}
          keepOriginalTags={props.wizardState.keepOriginalTags}
          setKeepOriginalTags={(value: boolean) =>
            props.setWizardState((state) => ({
              ...state,
              keepOriginalTags: value,
            }))
          }
          onChange={(tags) =>
            props.setWizardState((state) => ({ ...state, tags }))
          }
        />
      </CardContent>
    </StepContainer>
  );
};
