import { FormControl, FormLabel, Select } from '@mui/material';

export const EmptySelect = ({ title }: { title: string }) => {
  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>{title}</FormLabel>
      <Select size='small' disabled value='' />
    </FormControl>
  );
};
