import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  CardContent,
  Divider,
  Box,
} from '@mui/material';
import type { SnapshotPropertiesVolumePropertiesInner } from '@repo/api-gw-sdk';

import { EncryptionComponent } from '@/components/encryption/encryptionComponent';
import { TagsSection } from '@/components/tags/tagsSection';
import { VolumeSettingsSection } from '@/components/volumes/volumeSettings';
import type { StepProps } from '@/components/wizard/StepProps';

import type {
  VolumeConfigState,
  RestoreEc2InstanceState,
} from '../restoreEc2InstanceWizard';

export const SingleVolumeConfig = ({
  vol,
  initialSettings,
  volumeSettings,
  setWizardState,
  restoreAccountId,
  regionName,
  showEncryption,
}: {
  vol: SnapshotPropertiesVolumePropertiesInner;
  initialSettings: VolumeConfigState;
  volumeSettings: VolumeConfigState;
  setWizardState: StepProps<RestoreEc2InstanceState>['setWizardState'];
  restoreAccountId: string;
  regionName: string;
  showEncryption: boolean;
}) => {
  const setVolumesConfigState = <T extends keyof VolumeConfigState>(
    field: T,
    value: VolumeConfigState[T]
  ) => {
    setWizardState((state) => {
      const volumesConfigState = state.crossStepsState.volumesConfigState?.map(
        (volume) =>
          volume.volumeId === vol.volumeId
            ? {
                ...volume,
                [field]: value,
              }
            : volume
      );
      return {
        ...state,
        crossStepsState: {
          ...state.crossStepsState,
          volumesConfigState,
        },
      };
    });
  };

  return (
    <Accordion defaultExpanded={false} className='my-[2px]'>
      <AccordionSummary>
        <Typography variant='body1'>{vol.volumeName}</Typography>
      </AccordionSummary>
      <AccordionDetails className='p-0'>
        <CardContent className='p-0'>
          <VolumeSettingsSection
            initialSettings={initialSettings?.volumeSettings}
            settings={volumeSettings?.volumeSettings}
            keepOriginalSettings={volumeSettings.keepOriginalSettings}
            setKeepOriginalSettings={(keepOriginalSettings) =>
              setVolumesConfigState(
                'keepOriginalSettings',
                keepOriginalSettings
              )
            }
            onChange={(volumeSettings) =>
              setVolumesConfigState('volumeSettings', volumeSettings)
            }
          />
        </CardContent>
        {showEncryption && <Divider />}
        <Box sx={showEncryption ? {} : { display: 'none' }}>
          <CardContent className='px-0'>
            <EncryptionComponent
              encryption={volumeSettings.encryption}
              accountId={restoreAccountId}
              regionName={regionName}
              onChange={(encryption) =>
                setVolumesConfigState('encryption', encryption)
              }
            />
          </CardContent>
        </Box>
        <Divider />
        <CardContent className='px-0'>
          <TagsSection
            initialTags={initialSettings.tags || {}}
            tags={volumeSettings.tags || initialSettings.tags || {}}
            keepOriginalTags={volumeSettings.keepOriginalTags}
            setKeepOriginalTags={(keepOriginalTags) =>
              setVolumesConfigState('keepOriginalTags', keepOriginalTags)
            }
            onChange={(tags) => setVolumesConfigState('tags', tags)}
          />
        </CardContent>
      </AccordionDetails>
    </Accordion>
  );
};
