import { useViewParameters } from '@/contexts/useViewParameters';
import {
  createCombineCondition,
  isCombineCondition,
  type CombineCondition,
} from '@/types/advanceFilter';

import { FiltersPanelWrapper } from './filtersPanelWrapper';

export const FiltersDrillDownPanel = ({
  Component,
  onBack,
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
  Component: ({ filter }: { filter: CombineCondition }) => React.ReactNode;
  onBack: () => void;
}) => {
  const { query } = useViewParameters();
  const combineCondition = isCombineCondition(query)
    ? query
    : createCombineCondition();

  return (
    <FiltersPanelWrapper
      title={title}
      subtitle={subtitle}
      onBack={() => onBack()}
    >
      <Component filter={combineCondition} />
    </FiltersPanelWrapper>
  );
};
