import {
  apps,
  accountId,
  dataClasses,
  environment,
  resourceType,
  sourceRegion,
  subnets,
  vpc,
} from '@/components/queryBuilder/properties';
import { type FilterProperty } from '@/types/advanceFilter';

export const supportedControlProperties: FilterProperty[][] = [
  [resourceType, dataClasses, environment, apps],
  [accountId, sourceRegion, vpc, subnets],
];
