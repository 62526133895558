import type { User } from '@repo/api-gw-sdk';

import { UpsertUserStep } from './upsertUserStep';

import type { Step } from '../wizard/Step';

export type UpsertUserState = User & { email: string };

export const UpsertUserFlow: Step<UpsertUserState> = {
  name: 'Upsert User',
  Component: UpsertUserStep,
};
