import {
  createColumnHelper,
  type AccessorKeyColumnDef,
  type Column,
} from '@tanstack/react-table';

import type { FilterProperty } from '@/types/advanceFilter';

const columnHelper = createColumnHelper<unknown>();

export const createColumns = <T>(properties: FilterProperty[]) => {
  return properties
    .filter((x) => x.column)
    .map(
      (x) =>
        columnHelper.accessor(x.name, {
          header: x.displayName,
          ...x.column,
          meta: {
            ...x.column?.meta,
            properties: [x, ...(x.linkedProperties || [])],
          },
        }) as AccessorKeyColumnDef<T>
    );
};

export const createFields = <T>(properties: FilterProperty<T>[]) => {
  return properties
    .filter((x) => x.field)
    .map((property) => ({
      id: property.name,
      title: (entity: T) =>
        property.field!.titleKey?.(entity) || property.displayName,
      group: property.group,
      value: (val: T) => property.field!.value(val),
    }));
};

export const getProperties = <T>(column: Column<T>): FilterProperty[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (column.columnDef.meta as any)?.properties || [];
};
