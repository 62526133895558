import type { Integration } from '@repo/api-gw-sdk';

import { Wizard } from '@/components/wizard/Wizard';

import {
  UpsertIntegrationFlow,
  type UpsertIntegrationState,
} from './upsertIntegrationFlow';

export interface UpsertIntegrationPanelProps {
  entity: Integration;
  onSave: (integration: Integration) => void;
  onAbort: () => void;
}

export function IntegrationPanel(props: UpsertIntegrationPanelProps) {
  const save = (state: UpsertIntegrationState) => {
    props.onSave(state);
    return true;
  };

  const validate = (state: UpsertIntegrationState) => {
    if (
      !state.givenName ||
      state.givenName.trim() === '' ||
      state.givenName.length < 4
    ) {
      return 'Name is required';
    }
  };

  return (
    <Wizard<UpsertIntegrationState>
      onAbort={props.onAbort}
      onFinish={save}
      validate={validate}
      flow={[UpsertIntegrationFlow]}
      initialState={{
        ...props.entity,
      }}
      title={
        props.entity.clientId ? `Edit an Integration` : `Create an Integration`
      }
    />
  );
}
