import { AccordionDetails, Box, Divider } from '@mui/material';
import type {
  InventoryResource,
  Snapshot,
  SnapshotPropertiesVolumePropertiesInner,
} from '@repo/api-gw-sdk';

import { Accordion, AccordionSummary } from '@/components/accordion';
import type { BackupVault } from '@/data/vaults/backupVault';

import type { RestoreActionType } from './ec2SnapshotRow';
import { SnapshotDetailsBody } from './snapshotDetailsBody';
import { SnapshotDetailsHeader } from './snapshotDetailsHeader';

export const SnapshotDetails = ({
  snap,
  vault,
  isSelected,
  onSelect,
  onRestore,
  resource,
  onConvertSnapshot,
}: {
  snap: Snapshot;
  vault: BackupVault | undefined;
  isSelected?: boolean | undefined;
  onSelect?: () => void;
  onRestore: (
    volumes: SnapshotPropertiesVolumePropertiesInner[],
    actionType: RestoreActionType
  ) => void;
  onConvertSnapshot?: (
    volumes: SnapshotPropertiesVolumePropertiesInner[]
  ) => void;
  resource: InventoryResource;
}) => {
  return (
    <>
      <Divider />
      {onSelect ? (
        <Accordion
          sx={{ paddingY: '12px', paddingLeft: '40px' }}
          expanded={isSelected}
          onClick={onSelect}
        >
          <AccordionSummary>
            <SnapshotDetailsHeader
              snap={snap}
              vault={vault}
              isSelected={isSelected}
            />
          </AccordionSummary>
          <AccordionDetails>
            <SnapshotDetailsBody
              snapshot={snap}
              vault={vault}
              onRestore={onRestore}
              resource={resource}
              onConvertSnapshot={onConvertSnapshot}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box padding='24px 40px'>
          <Box paddingBottom='24px'>
            <SnapshotDetailsHeader
              snap={snap}
              vault={vault}
              isSelected={isSelected}
            />
          </Box>
          <SnapshotDetailsBody
            snapshot={snap}
            vault={vault}
            onRestore={onRestore}
            resource={resource}
            onConvertSnapshot={onConvertSnapshot}
          />
        </Box>
      )}
    </>
  );
};
