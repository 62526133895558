'use client';

import {
  Checkbox,
  Skeleton,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableBody as MuiTableBody,
  Table as MuiTable,
  TableContainer,
  Divider,
} from '@mui/material';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type {
  Cell,
  Column,
  ColumnDef,
  PaginationState,
  RowSelectionState,
  SortingState,
  Table as TableType,
} from '@tanstack/react-table';
import classnames from 'classnames';
import { Fragment, useMemo, useState, type CSSProperties } from 'react';

import { FiltersBar } from './filtersBar';
import tableStyles from './table.module.css';

import PaginationBar from '../shared/paginationBar';

export interface RowData<T> {
  data: T[];
  enableFilters: boolean;
  openFilters?: boolean;
  showFiltersBar: boolean;
  isLoading: boolean;
  isMultiSelect: boolean;
  multiSelectActionButton?: React.ReactNode;
  disableSorting?: boolean;
  sorting?: SortingState;
  selectedIds?: string[];
  enableColumnSelection: boolean;
  columns: ColumnDef<T>[];
  hiddenColumns?: string[];
  disableSelection?: boolean;
  pagination?: {
    pageIndex: number;
    pageSize: number;
    pageSizesOptions: readonly (number | { value: number; label: string })[];
    recordsCount?: number;
    recordsLimit?: number;
    onChange: (pagination: PaginationState) => void;
  };
  onRowSelectionChange?: (
    entities: T[],
    onRowContentChange?: () => Promise<void>
  ) => void;
  onRowContentChange?: () => Promise<void>;
  onSortingChanged?: (state: SortingState) => void;
  getSubRows?: (row: T) => T[];
  getDynamicColProps?: (cell: Cell<T, unknown>) => Record<string, unknown>;
  testId?: string;
  entityLabel: string;
  pinnedColumns?: string[];
  stickyHeader?: boolean;
}

const getSelectionColumn = <T,>(): ColumnDef<T> => ({
  id: 'select',
  enableHiding: false,
  size: 53,
  cell: ({ row }) => (
    <Checkbox
      {...{
        checked: row.getIsSelected(),
        disabled: !row.getCanSelect(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler(),
      }}
    />
  ),
});

export const Table = <T extends object>({
  data,
  openFilters,
  showFiltersBar = false,
  hiddenColumns,
  isMultiSelect,
  multiSelectActionButton,
  columns,
  sorting,
  isLoading,
  pagination,
  enableColumnSelection,
  selectedIds = [],
  disableSorting,
  disableSelection,
  onSortingChanged,
  onRowSelectionChange,
  onRowContentChange,
  getSubRows,
  getDynamicColProps,
  testId,
  entityLabel,
  pinnedColumns,
  stickyHeader,
  enableFilters,
}: RowData<T>) => {
  const selectedIndexes = useMemo(() => {
    const indexes = selectedIds.map((selectedId) =>
      data.findIndex((x) => 'id' in x && x.id === selectedId)
    );
    if (indexes.length >= 0) {
      return indexes.reduce<Record<string, boolean>>((agg, selectedIndex) => {
        agg[selectedIndex] = true;
        return agg;
      }, {});
    }

    return {};
  }, [selectedIds, data]);
  const [isTableScrolledHorizontally, setTableScrolledHorizontally] =
    useState(false);
  const [isTableScrolledVertically, setTableScrolledVertically] =
    useState(false);

  const extendedColumns = useMemo(
    () => [
      ...(isMultiSelect && !disableSelection ? [getSelectionColumn<T>()] : []),
      ...columns,
    ],
    [isMultiSelect, disableSelection, columns]
  );

  const table = useReactTable({
    data,
    columns: extendedColumns,
    enableRowSelection: true,
    enableSortingRemoval: false,
    enableMultiRowSelection: true,

    initialState: {
      columnPinning: {
        left: [
          ...(pinnedColumns?.length && isMultiSelect && !disableSelection
            ? ['select']
            : []),
          ...(pinnedColumns || []),
        ],
        right: [],
      },
      columnVisibility: hiddenColumns?.reduce<Record<string, boolean>>(
        (agg, x) => {
          agg[x] = false;
          return agg;
        },
        {}
      ),
    },
    state: {
      sorting,
      rowSelection: selectedIndexes,
    },

    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => getSubRows?.(row),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: (state) => {
      if (!disableSorting && sorting && onSortingChanged) {
        const newState = typeof state === 'function' ? state(sorting) : state;
        onSortingChanged(newState);
      }
    },
    onRowSelectionChange: (state) => {
      if (disableSelection) {
        return;
      }

      const newState =
        typeof state === 'function' ? state(selectedIndexes) : state;

      const selectedEntities = getSelectedEntities(table, newState);
      onRowSelectionChange?.(selectedEntities, onRowContentChange);
    },
  });

  const multiSelect =
    isMultiSelect && !disableSelection
      ? {
          getIsAllRecordsSelected: table.getIsAllRowsSelected,
          getIsSomeRecordsSelected: table.getIsSomeRowsSelected,
          getToggleAllRecordsSelectedHandler:
            table.getToggleAllRowsSelectedHandler,
          ActionButton: multiSelectActionButton,
        }
      : undefined;

  const onPageChange = (page: number) => {
    pagination?.onChange({
      pageSize: pagination.pageSize,
      pageIndex: page,
    });
  };

  const onRowsPerPageChange = (pageIndex: number, pageSize: number) => {
    pagination?.onChange({
      pageSize,
      pageIndex,
    });
  };

  return (
    <>
      {showFiltersBar && <FiltersBar />}
      {(isMultiSelect ||
        pagination ||
        enableColumnSelection ||
        enableFilters) && (
        <>
          <Divider />
          <PaginationBar
            pagination={pagination}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            entityLabel={entityLabel}
            dataLength={data.length}
            selection={{
              multiSelect,
              selectedRecordsCount: selectedIds.length,
              isSelectable: true,
            }}
            isLoading={isLoading}
            filtering={{
              openFilters,
              enableFilters,
              enableColumnSelection,
              getAllColumns: table.getAllColumns,
            }}
          />
        </>
      )}
      <TableContainer
        className='flex-grow min-h-[500px]'
        onScroll={(event) => {
          const target = event.target as HTMLDivElement;
          if (pinnedColumns?.length && typeof target.scrollLeft === 'number') {
            const isScrolled = target.scrollLeft > 0;
            if (isScrolled !== isTableScrolledHorizontally) {
              setTableScrolledHorizontally(isScrolled);
            }
          }

          if (typeof target.scrollTop === 'number') {
            const isScrolled = target.scrollTop > 0;
            if (isScrolled !== isTableScrolledVertically) {
              setTableScrolledVertically(isScrolled);
            }
          }
        }}
      >
        <MuiTable
          className={tableStyles.table}
          data-testid={testId}
          stickyHeader={stickyHeader}
        >
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    sx={getCommonPinningStyles(
                      header.column,
                      isTableScrolledHorizontally
                    )}
                    className={classnames({
                      'cursor-pointer select-none':
                        !disableSorting && header.column.getCanSort(),
                      [tableStyles.sorted]: header.column.getIsSorted(),
                      [tableStyles.pinned]:
                        !isLoading && header.column.getIsPinned() === 'left',
                      [tableStyles.scrollingVertically]:
                        isTableScrolledVertically,
                    })}
                    onClick={() => {
                      if (!disableSorting && header.column.getCanSort()) {
                        header.column.toggleSorting();
                      }
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div className='whitespace-pre-line justify-between flex items-center min-h-[20px]'>
                          <Typography variant='subtitle1' className='w-full'>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Typography>
                          {header.column.getCanSort() && (
                            <i
                              style={{
                                color: 'var(--mui-palette-text-gray1)',
                              }}
                              className={classnames('w-[20px] h-[20px]', {
                                hidden: !header.column.getIsSorted(),
                                'ri-arrow-down-s-line':
                                  header.column.getIsSorted() === 'asc',
                                'ri-arrow-up-s-line':
                                  header.column.getIsSorted() === 'desc',
                              })}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody<T>
            data={data}
            isLoading={isLoading}
            isMultiSelect={isMultiSelect}
            table={table}
            getDynamicColProps={getDynamicColProps}
            isTableScrolledHorizontally={isTableScrolledHorizontally}
            disableSelection={disableSelection}
          />
        </MuiTable>
      </TableContainer>
    </>
  );
};

const LoadingState = ({
  numOfRows,
  numOfColumns,
}: {
  numOfRows: number;
  numOfColumns: number;
}) => {
  return (
    <MuiTableBody>
      {[...Array(numOfRows)].map((_, i) => (
        <TableRow key={i}>
          {[...Array(numOfColumns)].map((_, j) => (
            <TableCell key={j} className='text-center'>
              <Skeleton variant='text' />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </MuiTableBody>
  );
};

const NoDataState = ({ columnCount }: { columnCount: number }) => {
  return (
    <MuiTableBody>
      <TableRow>
        <TableCell colSpan={columnCount} className='text-center'>
          {'No data available'}
        </TableCell>
      </TableRow>
    </MuiTableBody>
  );
};

function TableBody<T>({
  table,
  data,
  isLoading,
  getDynamicColProps,
  isTableScrolledHorizontally,
  isMultiSelect,
  disableSelection,
}: {
  table: TableType<T>;
  isLoading: boolean;
  getDynamicColProps?: (cell: Cell<T, unknown>) => Record<string, unknown>;
  data: T[];
  isTableScrolledHorizontally: boolean;
  isMultiSelect: boolean;
  disableSelection?: boolean;
}) {
  if (isLoading && !data.length) {
    return (
      <LoadingState
        numOfRows={10}
        numOfColumns={table.getVisibleFlatColumns().length}
      />
    );
  }

  if (!isLoading && table.getRowModel().rows.length === 0) {
    return <NoDataState columnCount={table.getVisibleFlatColumns().length} />;
  }

  if (table.getRowModel().rows.length > 0) {
    return (
      <MuiTableBody
        className={`${classnames({
          'opacity-30': isLoading,
        })} align-top`}
      >
        {table.getRowModel().rows.map((row) => {
          return (
            <Fragment key={row.id}>
              <TableRow
                className={classnames({
                  [tableStyles.selected]: row.getIsSelected(),
                  'cursor-auto': disableSelection,
                })}
                onClick={() => {
                  if (!isLoading) {
                    table.setRowSelection({ [row.id]: !row.getIsSelected() });
                  }
                }}
              >
                {row.getVisibleCells().map((cell, index) => (
                  <TableCell
                    key={cell.id}
                    {...getDynamicColProps?.(cell)}
                    sx={getCommonPinningStyles(
                      cell.column,
                      isTableScrolledHorizontally
                    )}
                    className={classnames({
                      [tableStyles.pinned]:
                        cell.column.getIsPinned() === 'left',
                      [tableStyles.multi]: isMultiSelect,
                    })}
                    onClick={(event) => {
                      if (isMultiSelect && index === 0 && !disableSelection) {
                        event.stopPropagation();
                        row.getToggleSelectedHandler()(event);
                      }
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            </Fragment>
          );
        })}
      </MuiTableBody>
    );
  }
}

function getSelectedEntities<T>(
  table: TableType<T>,
  newState: RowSelectionState
) {
  const rows = table.getRowModel().rows;
  const selectedIds = Object.keys(newState).reduce<T[]>((agg, index) => {
    if (newState[index]) {
      const row = rows[parseInt(index)];
      if (row) {
        agg.push(row.original);
      }
    }
    return agg;
  }, []);

  return selectedIds;
}

const getCommonPinningStyles = <T,>(
  column: Column<T>,
  isTableScrolledHorizontally: boolean
): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left');

  return {
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    minWidth: `${column.columnDef.minSize}px`,
    width: column.getSize(),
    ...(isLastLeftPinnedColumn
      ? isTableScrolledHorizontally
        ? { borderRight: '3px solid var(--border-color) !important' }
        : { paddingRight: '18px !important' }
      : {}),
  };
};
