import { Snackbar } from '@mui/material';

interface SnackbarProps {
  message: string;
  isOpen: boolean;
  setSnackbarOpen: (isOpen: boolean) => void;
}

export default function InfoSnackbar(props: SnackbarProps) {
  const { message, isOpen, setSnackbarOpen } = props;

  return (
    <Snackbar
      sx={{ marginLeft: '62px' }}
      open={isOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={3000}
      onClose={() => setSnackbarOpen(false)}
      message={message}
    />
  );
}
