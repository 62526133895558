import { Stack, Typography, useTheme } from '@mui/material';
import type { DataAccessRule, Role } from '@repo/api-gw-sdk';
import React from 'react';

import OptionMenu from '@/@core/components/option-menu';
import type { OptionMenuItemType } from '@/@core/components/option-menu/types';
import { Icon } from '@/components/shared/icon';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';

import type { NamedPermissionDescriptor } from './types';
import { getAssignedDataAccessRule } from './utils';

export const PermissionOptionMenu = ({
  icon,
  isPermissionAuthorized,
  permission,
  role,
  onBlocked,
  onAllowed,
  createDataAccessRule,
  assignDataAccessRule,
}: {
  role: Role;
  permission: NamedPermissionDescriptor;
  isPermissionAuthorized: boolean;
  icon: React.ReactNode;
  onBlocked: () => void;
  onAllowed: () => void;
  createDataAccessRule: () => void;
  assignDataAccessRule: (dataAccessRule: DataAccessRule) => void;
}) => {
  const { dataAccessRoles } = useFeatureFlags();

  const isConditionalPermission = !!(
    dataAccessRoles && permission.isConditional
  );

  const assignedDataAccessRule = getAssignedDataAccessRule(
    dataAccessRoles,
    permission,
    role
  );

  const allConditions = Object.values(role.dataAccessRules || {});

  const options = usePermissionOptions(
    assignedDataAccessRule,
    isPermissionAuthorized,
    isConditionalPermission,
    allConditions
  );

  return (
    <OptionMenu
      iconButtonProps={{ className: 'p-0' }}
      icon={icon}
      options={options}
      onOptionSelected={(option) => {
        if (typeof option !== 'string') {
          const chosenIndex = options.indexOf(option);
          if (chosenIndex === 0) {
            onBlocked();
          } else if (chosenIndex === options.length - 1) {
            onAllowed();
          } else {
            if (option.subMenu?.length || option.divider) {
              return;
            }

            if (option.id === 'create-new-condition') {
              createDataAccessRule();
            } else {
              assignDataAccessRule(
                allConditions.find((x) => x.name === option.id)!
              );
            }
          }
        }
      }}
    />
  );
};

const usePermissionOptions = (
  assignedDataAccessRule: DataAccessRule | undefined,
  isPermissionAuthorized: boolean,
  isConditionalPermission: boolean | undefined,
  allConditions: DataAccessRule[]
): OptionMenuItemType[] => {
  const theme = useTheme();

  const menuItemStyles = (isSelected: boolean) => {
    return {
      height: '40px',
      padding: '4px 16px 4px 16px',
      borderRadius: 0,

      ...(isSelected
        ? {
            backgroundColor: 'var(--mui-palette-primary-main)',
            color: 'var(--mui-palette-background-default)',
          }
        : {}),

      '&:hover': {
        backgroundColor: isSelected
          ? 'var(--mui-palette-primary-main)'
          : theme.palette.mode === 'dark'
            ? '#1A2027'
            : '#E7F0F3',
      },
    };
  };

  const selectedIndex = isConditionalPermission
    ? assignedDataAccessRule
      ? 1
      : isPermissionAuthorized
        ? 2
        : 0
    : isPermissionAuthorized
      ? 1
      : 0;

  return [
    { icon: 'material-symbols-close-rounded', text: 'Blocked' },
    ...(isConditionalPermission
      ? [
          {
            id: 'create-new-condition',
            icon: 'material-symbols-rule-rounded',
            text: 'Conditional',
            subMenu: allConditions.length
              ? [
                  ...allConditions.map((x, index) => ({
                    id: x.name,
                    menuItemProps: {
                      sx: menuItemStyles(
                        !!(
                          assignedDataAccessRule &&
                          allConditions.indexOf(assignedDataAccessRule) ===
                            index
                        )
                      ),
                    },
                    text: <Option text={x.name!} />,
                  })),
                  { divider: true },
                  {
                    id: 'create-new-condition',
                    menuItemProps: {
                      sx: menuItemStyles(false),
                    },
                    text: (
                      <Option
                        icon='material-symbols-add-rounded'
                        text='Create Access Condition'
                      />
                    ),
                  },
                ]
              : undefined,
          },
        ]
      : []),

    { icon: 'material-symbols-check', text: 'Allowed' },
  ].map((x, index) => ({
    subMenu: x.subMenu,
    menuItemProps: { sx: menuItemStyles(selectedIndex === index) },
    text: <Option icon={x.icon} text={x.text} subMenu={x.subMenu} />,
  }));
};

const Option = ({
  icon,
  text,
  subMenu,
}: {
  icon?: string;
  text: string;
  subMenu?: OptionMenuItemType['subMenu'];
}) => (
  <Stack alignItems='center' gap='12px' direction='row'>
    {icon && (
      <Icon
        iconClass={icon}
        sx={{
          '& i': {
            color: 'currentColor',
          },
        }}
      />
    )}
    <Typography className='pr-[8px]' color='currentColor'>
      {text}
    </Typography>
    {subMenu && (
      <Icon
        iconClass='material-symbols-chevron-right-rounded'
        sx={{
          '& i': {
            color: 'currentColor',
          },
        }}
      />
    )}
  </Stack>
);
