import type { ComponentProps } from 'react';

import { InventoryInstancePanel } from '@/app/(dashboard)/inventory/[[...ids]]/inventoryInstancePanel';
import BackupPolicyPanel from '@/app/(dashboard)/settings/backup-policies/BackupPolicySidePanel';
import { CreateAccountPanel } from '@/app/(dashboard)/settings/cloud-accounts/[type]/createAccountPanel';
import ControlWizard from '@/app/(dashboard)/settings/controls/ControlWizard';

import type { Panels } from './panels';

import { FiltersDrillDownPanel } from '../filters/filtersDrillDownPanel';
import { FiltersPanel } from '../filters/filtersPanel';
import { GroupPanel } from '../groups/groupPanel';
import { IntegrationPanel } from '../integrations/integrationPanel';
import { CustomRolePanel } from '../permissions/customRolePanel';
import { RestoreEc2InstanceWizard } from '../restore/restoreEc2InstanceWizard';
import { RestoreFileWizard } from '../restore/restoreFileWizard';
import { RestoreMongoAtlasWizard } from '../restore/restoreMongoAtlasWizard';
import { RestoreRDSWizard } from '../restore/restoreRDSWizard';
import { RestoreS3Wizard } from '../restore/restoreS3Wizard';
import { RestoreVolumeWizard } from '../restore/restoreVolumeWizard';
import { SamlPanel } from '../saml/samlPanel';
import { UpsertUserPanel } from '../users/upsertUserPanel';
import { UserPanel } from '../users/userPanel';
import { EditBackupVaultPanel } from '../vaults/EditBackupVaultPanel';

type PanelsMap<T extends PanelTypes> = {
  [k in T['panel']]: (
    props: Extract<PanelTypes, { panel: k }>['props']
  ) => React.ReactNode;
};

export const PanelsMap: PanelsMap<PanelTypes> = {
  InventoryInstance: InventoryInstancePanel,
  RestoreVolumeWizard: RestoreVolumeWizard,
  RestoreEc2InstanceWizard: RestoreEc2InstanceWizard,
  RestoreFileWizard: RestoreFileWizard,
  RestoreRDSWizard: RestoreRDSWizard,
  RestoreS3Wizard: RestoreS3Wizard,
  RestoreMongoAtlasWizard: RestoreMongoAtlasWizard,
  EditBackupVault: EditBackupVaultPanel,
  BackupPolicy: BackupPolicyPanel,
  CustomRole: CustomRolePanel,
  Integration: IntegrationPanel,
  Control: ControlWizard,
  User: UserPanel,
  Group: GroupPanel,
  SAML: SamlPanel,
  Filters: FiltersPanel,
  FiltersDrillDown: FiltersDrillDownPanel,
  CloudAccount: CreateAccountPanel,
  UpsertUser: UpsertUserPanel,
};

export type PanelTypes =
  | {
      panel: typeof Panels.InventoryInstance;
      props: ComponentProps<typeof InventoryInstancePanel>;
    }
  | {
      panel: typeof Panels.EditBackupVault;
      props: ComponentProps<typeof EditBackupVaultPanel>;
    }
  | {
      panel: typeof Panels.RestoreVolumeWizard;
      props: ComponentProps<typeof RestoreVolumeWizard>;
    }
  | {
      panel: typeof Panels.RestoreEc2InstanceWizard;
      props: ComponentProps<typeof RestoreEc2InstanceWizard>;
    }
  | {
      panel: typeof Panels.RestoreFileWizard;
      props: ComponentProps<typeof RestoreFileWizard>;
    }
  | {
      panel: typeof Panels.RestoreRDSWizard;
      props: ComponentProps<typeof RestoreRDSWizard>;
    }
  | {
      panel: typeof Panels.RestoreS3Wizard;
      props: ComponentProps<typeof RestoreS3Wizard>;
    }
  | {
      panel: typeof Panels.RestoreMongoAtlasWizard;
      props: ComponentProps<typeof RestoreMongoAtlasWizard>;
    }
  | {
      panel: typeof Panels.User;
      props: ComponentProps<typeof UserPanel>;
    }
  | {
      panel: typeof Panels.Group;
      props: ComponentProps<typeof GroupPanel>;
    }
  | {
      panel: typeof Panels.SAML;
      props: ComponentProps<typeof SamlPanel>;
    }
  | {
      panel: typeof Panels.Filters;
      props: ComponentProps<typeof FiltersPanel>;
    }
  | {
      panel: typeof Panels.FiltersDrillDown;
      props: ComponentProps<typeof FiltersDrillDownPanel>;
    }
  | {
      panel: typeof Panels.BackupPolicy;
      props: ComponentProps<typeof BackupPolicyPanel>;
    }
  | {
      panel: typeof Panels.CustomRole;
      props: ComponentProps<typeof CustomRolePanel>;
    }
  | {
      panel: typeof Panels.Integration;
      props: ComponentProps<typeof IntegrationPanel>;
    }
  | {
      panel: typeof Panels.Control;
      props: ComponentProps<typeof ControlWizard>;
    }
  | {
      panel: typeof Panels.CloudAccount;
      props: ComponentProps<typeof CreateAccountPanel>;
    }
  | {
      panel: typeof Panels.UpsertUser;
      props: ComponentProps<typeof UpsertUserPanel>;
    };
