import { Box } from '@mui/material';

export const PendingCircle = ({
  backgroundColor,
}: {
  backgroundColor?: string;
}) => {
  if (!backgroundColor) {
    return null;
  }

  return (
    <Box
      component='span'
      className='p-[2px] rounded-full'
      sx={{ border: `2px solid ${backgroundColor}` }}
    >
      <Box
        component='span'
        className='w-[4px] h-[4px] flex items-center justify-center rounded-full'
        sx={{ backgroundColor: backgroundColor }}
      ></Box>
    </Box>
  );
};
