import { AccountCloudEnum } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import { RDSConfiguration } from './steps/rdsConfiguration';
import { SelectTargetAccount } from './steps/selectTargetAccount';

import type { Encryption } from '../encryption/encryptionComponent';
import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreRDSState {
  resourceId: string;
  snapshotId: string;
  resourceName?: string;
  restoreAccountCloudProvider?: AccountCloudEnum;
  restoreAccountId?: string;
  regionName?: string;
  securityGroupId?: string;
  rdsSubnetGroupName?: string;
  vpc?: string;
  tags?: Record<string, string>;
  keepOriginalTags?: boolean;
  encryption?: Encryption;
  initialRegionName: string;
}

const restoreRDSFlow: Step<RestoreRDSState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccount,
  next: [
    {
      name: 'RDSConfiguration',
      Component: RDSConfiguration,
    },
  ],
};

export const RestoreRDSWizard = ({
  resourceId,
  snapshotId,
  sourceRegion,
}: {
  resourceId: string;
  snapshotId: string;
  sourceRegion?: string;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: { id: resourceId, onClose: () => setIsOpen(false) },
    });

    return true;
  };

  return (
    <Wizard<RestoreRDSState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreRDSFlow]}
      initialState={{
        initialRegionName: sourceRegion || 'us-east-1',
        snapshotId,
        resourceId,
        restoreAccountCloudProvider: AccountCloudEnum.Aws,
        keepOriginalTags: true,
      }}
      title={`Restore RDS`}
      stepperLabels={['Restore Account', 'Configuration']}
    />
  );
};
