import { useState } from 'react';

import { SidePanelWrapper } from '@/components/layout/sidePanel/sidePanelWrapper';
import { Icon } from '@/components/shared/icon';
import { useWorkspace } from '@/contexts/useWorkspace';

import type { Step } from './Step';

import InfoSnackbar from '../infoSnackbar';

export interface WizardProps<T> {
  onFinish: (state: T) => boolean;
  onAbort: (origin?: 'back') => void;
  validateStep?: (state: T) => string | undefined;
  validate?: (state: T) => string | undefined;
  title: string;
  initialState: T;
  flow: Step<T>[];
  stepperLabels?: string[];
}

export const Wizard = <T,>(props: WizardProps<T>) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [steps, setSteps] = useState(props.flow);
  const currentStep = steps[steps.length - 1];
  const [wizardState, setWizardState] = useState<T>(props.initialState);
  const [err, setErr] = useState<string | undefined>();
  const { rightPanel } = useWorkspace();
  const { setIsOpen } = rightPanel;

  const back = () => {
    if (steps.length <= 1) {
      props.onAbort('back');
    } else {
      setSteps(steps.slice(0, steps.length - 1));
    }
  };

  const next = (step: Step<T> | undefined) => {
    const err = props.validateStep?.(wizardState);
    if (err) {
      setErr(err);
      return;
    } else {
      setErr(undefined);
    }

    if (step) {
      setSteps([...steps, step]);
    }
  };

  const finish = () => {
    const err = props.validate?.(wizardState);
    if (err) {
      setErr(err);
      return;
    } else {
      setErr(undefined);
    }

    if (props.onFinish(wizardState)) {
      setIsOpen(false);
    }
  };

  return (
    <SidePanelWrapper
      title={props.title}
      icon={
        <Icon
          iconClass={`material-symbols-chevron-left-rounded`}
          className='cursor-pointer'
          onClick={back}
        />
      }
      close={props.onAbort}
    >
      <currentStep.Component
        stepperLabels={props.stepperLabels ?? []}
        currentStep={currentStep}
        back={back}
        abort={props.onAbort}
        error={err}
        wizardState={wizardState}
        setWizardState={setWizardState}
        finish={finish}
        setNextStep={next}
        onSuccess={(message: string) => {
          setSuccessMessage(message);
          setSnackbarOpen(true);
        }}
      />
      <InfoSnackbar
        message={successMessage}
        isOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    </SidePanelWrapper>
  );
};
