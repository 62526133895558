import { Stack } from '@mui/material';
import { useState } from 'react';

import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';

import { StepContainer } from '../../wizard/StepContainer';
import { BucketSelection } from '../bucketSelection';
import type { RestoreS3State } from '../restoreS3Wizard';

export const S3Configuration = (props: StepProps<RestoreS3State>) => {
  const {
    wizardState: stepState,
    stepperLabels,
    abort,
    back,
    onSuccess,
  } = props;
  const {
    restoreBucketMode,
    restoreBucketName,
    restoreBucketNameOverride,
    restoreBucketSelectionPrefix,
    restoreBucketManualPrefix,
    restoreAccountId,
    snapshotId,
    resourceId,
  } = stepState;
  const dal = useDAL();

  const [error, setError] = useState<string | undefined>();

  const onNextClick = () => {
    setError(undefined);
    const bucketName =
      restoreBucketMode === 'select'
        ? restoreBucketName
        : restoreBucketNameOverride;

    const restoreBucketPrefix =
      restoreBucketMode === 'select'
        ? restoreBucketSelectionPrefix
        : restoreBucketManualPrefix;

    // for saving the bucket name between different restores
    void dal.preferences.updateUserPref(PreferencesKey.RestoreBucket, {
      bucketName,
    });

    void dal.restore.s3Buckets
      .restore(resourceId, snapshotId, {
        restoreAccountId: restoreAccountId!,
        restoreBucketName: bucketName!,
        restoreBucketPrefix: restoreBucketPrefix,
      })
      .then(() => {
        abort();
        onSuccess('Restore job has started!');
      })
      .catch((e) => {
        setError(`Failed to restore bucket: ${e.message}`);
      });
  };

  return (
    <StepContainer
      sx={{ padding: '0' }}
      stepperLabels={stepperLabels}
      stepperIndex={stepperLabels.length - 1}
      nextButtonText='Restore'
      onBackClick={back}
      canGoNext={() => {
        return restoreBucketMode === 'select'
          ? !!restoreBucketName
          : !!restoreBucketNameOverride;
      }}
      navigationComponent={
        error && (
          <Stack alignItems='center' direction='row' sx={{ color: 'red' }}>
            <i className='ri-error-warning-line mr-[8px] text-[18px]' />
            {error}
          </Stack>
        )
      }
      onNextClick={onNextClick}
    >
      <BucketSelection {...props} />
    </StepContainer>
  );
};
