import { Typography } from '@mui/material';

export default function SelectPlaceholder({
  title = 'Select',
}: {
  title?: string;
}) {
  return (
    <Typography alignContent='center' component={'span'}>
      {title}
    </Typography>
  );
}
