import { Radio, Stack, Typography } from '@mui/material';
import type { Snapshot } from '@repo/api-gw-sdk';
import classnames from 'classnames';

import { SnapshotIndicator } from '@/components/snapshots/SnapshotIndicator';
import type { BackupVault } from '@/data/vaults/backupVault';
import { isDateValid } from '@/utils/dateTime';
import { dayjs } from '@/utils/dayjs';

import { PendingCircle } from './pendingCircle';

interface SnapshotDetailsHeaderProps {
  snap: Snapshot;
  vault: BackupVault | undefined;
  isSelected?: boolean | undefined;
  volume?: string; // todo: remove this once merging fullInstanceRestore
  onSelect?: () => void;
}

export const SnapshotDetailsHeader = (props: SnapshotDetailsHeaderProps) => {
  const { snap, isSelected, onSelect, vault, volume } = props;

  const date = isDateValid(snap.pointInTime)
    ? snap.pointInTime
    : snap.createTime;

  return (
    <Stack
      className={classnames('flex-1', { 'cursor-pointer': !!onSelect })}
      direction='row'
      gap='16px'
      alignItems='center'
      onClick={() => onSelect?.()}
    >
      {onSelect && <Radio size='small' checked={isSelected} />}
      {vault &&
        ((snap.status as unknown) === 'PENDING_DISABLED' ? (
          <PendingCircle backgroundColor={vault.backgroundColor} />
        ) : (
          <SnapshotIndicator backgroundColor={vault.backgroundColor || null} />
        ))}
      {volume && <Typography variant='body1'>{volume}</Typography>}
      <Typography variant='body1'>
        {((snap.status as unknown) === 'PENDING_DISABLED'
          ? 'Started at '
          : '') + dayjs(date).format('HH:mm:ss')}
      </Typography>
    </Stack>
  );
};
