import {
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import type { StepProps } from '@/components/wizard/StepProps';

import type { UpsertIntegrationState } from './upsertIntegrationFlow';

const handleCopy = async (text: string) => {
  if (!text) return;
  return navigator.clipboard.writeText(text);
};

export const IntegrationTokenReveal = (
  props: StepProps<UpsertIntegrationState>
) => {
  const [copyClientIdSuccess, setCopyClientIdSuccess] = useState(false);
  const [copyClientSecretSuccess, setCopyClientSecretSuccess] = useState(false);

  return (
    <Stack
      sx={{ height: '100%' }}
      alignItems='center'
      justifyContent='center'
      gap='40px'
    >
      <Stack gap='24px'>
        <Typography
          sx={{ textAlign: 'center' }}
          className='font-light max-w-[540px]'
        >
          Your integration’s client credentials are ready! Use the client ID and
          secret to authenticate Eon API calls. Learn how to authenticate
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            border: '1px solid #EC6924',
            padding: '8px 12px',
            borderRadius: '4px',
            color: '#EC6924',
          }}
        >
          Your client secret can’t be retrieved after you close, so save it in a
          secure place.
        </Typography>
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        gap='24px'
        sx={{
          border: '1px solid var(--mui-palette-divider)',
          borderRadius: '4px',
          padding: '12px 24px',
        }}
      >
        <Typography className='font-bold w-[90px]'>Client ID</Typography>
        <Divider orientation='vertical' flexItem />
        <Typography className='w-[582px]'>
          {props.wizardState.clientId}
        </Typography>
        <Divider orientation='vertical' flexItem />
        <Tooltip
          title={copyClientIdSuccess ? 'Copied!' : 'Copy to clipboard'}
          arrow
        >
          <IconButton
            type='button'
            sx={{ p: '10px' }}
            aria-label='search'
            onClick={() =>
              void handleCopy(props.wizardState.clientId).then(
                () => {
                  setCopyClientIdSuccess(true);
                  setTimeout(() => setCopyClientIdSuccess(false), 2000); // Reset the tooltip after 2 seconds
                },
                () => setCopyClientIdSuccess(false)
              )
            }
          >
            <i className='material-symbols-content-copy' />
          </IconButton>
        </Tooltip>
      </Stack>

      <Stack
        direction='row'
        alignItems='center'
        gap='24px'
        sx={{
          border: '1px solid var(--mui-palette-divider)',
          borderRadius: '4px',
          padding: '12px 24px',
        }}
      >
        <Typography className='font-bold w-[90px]'>Client Secret</Typography>
        <Divider orientation='vertical' flexItem />
        <Typography
          className='w-[582px]'
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {props.wizardState.clientSecret}
        </Typography>
        <Divider orientation='vertical' flexItem />
        <Tooltip
          title={copyClientSecretSuccess ? 'Copied!' : 'Copy to clipboard'}
          arrow
        >
          <IconButton
            type='button'
            sx={{ p: '10px' }}
            aria-label='search'
            onClick={() =>
              void handleCopy(props.wizardState.clientSecret!).then(
                () => {
                  setCopyClientSecretSuccess(true);
                  setTimeout(() => setCopyClientSecretSuccess(false), 2000); // Reset the tooltip after 2 seconds
                },
                () => setCopyClientSecretSuccess(false)
              )
            }
          >
            <i className='material-symbols-content-copy' />
          </IconButton>
        </Tooltip>
      </Stack>
      <Button
        variant='contained'
        onClick={props.finish}
        className='font-semibold'
      >
        Close
      </Button>
    </Stack>
  );
};
