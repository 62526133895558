'use client';

import type {
  DatesSetArg,
  DayCellContentArg,
  EventContentArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import type { SnapshotPropertiesVolumePropertiesInner } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import { PITCalendarWrapper } from './PITCalendarWrapper';
import { SnapshotIndicator } from './SnapshotIndicator';

import { PendingCircle } from '../panels/instance/tabs/pendingCircle';

export interface SnapshotMetadata {
  id: string;
  vaultId: string;
  pointInTime: Date;
  properties?: { volumeProperties?: SnapshotPropertiesVolumePropertiesInner[] };
}

export interface PointInTimeEvent {
  indicatorColor: string | null;
  vaultName?: string;
  start: Date;
  status: string;
}

interface InternalPointInTimeEvent extends PointInTimeEvent {
  additionalEventsInDate?: number;
}

export interface PointsInTimeCalendarProps {
  isLoading?: boolean;
  events: PointInTimeEvent[];
  selectedDate: Date | undefined;
  onDateSelected: (date: Date | undefined) => void;
  onDateRangeChanged: (start: Date, end: Date) => void;
}

export const PointsInTimeCalendar = (props: PointsInTimeCalendarProps) => {
  const [summarizedEvents, setSummarizedEvents] = useState<
    InternalPointInTimeEvent[]
  >([]);

  useEffect(() => {
    const map = new Map<string, InternalPointInTimeEvent[]>();
    const sorted = props.events.sort(
      (a, b) => a.start.getTime() - b.start.getTime()
    );

    sorted.forEach((event) => {
      const date = event.start.toDateString();
      const events = map.get(date) || [];
      if (events.length === 2) {
        events[1].additionalEventsInDate =
          (events[1].additionalEventsInDate || 0) + 1;
      } else {
        events.push(event);
      }
      map.set(date, events);
    });

    setSummarizedEvents([...map.values()].flat());
  }, [props.events]);

  return (
    <PITCalendarWrapper>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView='dayGridMonth'
        weekends={true}
        fixedWeekCount={false}
        showNonCurrentDates={false}
        buttonText={{ today: 'Today' }}
        dayCellContent={renderDayCell}
        dayCellClassNames={(args) => {
          const classes: string[] = [];
          const date = new Date(args.date.toDateString());
          if (props.isLoading) {
            classes.push('cell-loading');
          }

          if (props.selectedDate?.getTime() === date.getTime()) {
            classes.push('cell-selected');
          }
          return classes;
        }}
        dayCellDidMount={(args) => {
          args.el.onclick = () => {
            const dateStr = args.date.toDateString();
            const date = new Date(dateStr);
            props.onDateSelected(date);
          };
        }}
        selectable={true}
        dayHeaders={false}
        events={summarizedEvents}
        eventContent={renderEventContent}
        datesSet={(args: DatesSetArg) => {
          props.onDateRangeChanged(args.start, args.end);
        }}
        height='auto'
      />
    </PITCalendarWrapper>
  );
};

function renderEventContent(args: EventContentArg) {
  return (
    <>
      {args.event._def.extendedProps.status === 'PENDING_DISABLED' ? (
        <PendingCircle
          backgroundColor={args.event._def.extendedProps.indicatorColor}
        />
      ) : (
        <SnapshotIndicator
          backgroundColor={args.event._def.extendedProps.indicatorColor || null}
          title={args.event._def.extendedProps.vaultName}
        />
      )}
      {args.event._def.extendedProps.additionalEventsInDate &&
        args.event._def.extendedProps.additionalEventsInDate > 0 && (
          <span className='ml-[4px]'>
            +{args.event._def.extendedProps.additionalEventsInDate}
          </span>
        )}
    </>
  );
}

const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
function renderDayCell(arg: DayCellContentArg) {
  return (
    <div>
      {weekday[arg.date.getDay()]} {arg.dayNumberText}
    </div>
  );
}
