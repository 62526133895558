import {
  Button,
  FormControl,
  FormLabel,
  Stack,
  MenuItem as MenuItemMui,
  Select,
} from '@mui/material';
import type { Role, Viewer, Project } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { rolesComparer } from '@/configs/roles';
import { useRoles } from '@/contexts/useRoles';
import { useUser } from '@/contexts/useUser';

function getAvailableRoles(
  roleToProjectsMappings: Viewer['roleToProjectsMappings'] | undefined,
  rolesMap: Record<string, Role>,
  selectedProjectId: string | null
) {
  return Object.entries(roleToProjectsMappings || {})
    .reduce<Role[]>((agg, entry) => {
      const role = rolesMap[entry[0]];
      if (role) {
        if (!role.isProjectRole) {
          agg.push(role);
        } else if (entry[1]?.ids?.includes(selectedProjectId || '')) {
          agg.push(role);
        }
      }

      return agg;
    }, [])
    .sort(rolesComparer);
}

export const ChangeScope = ({
  onCancel,
  projects,
}: {
  onCancel: () => void;
  projects: Project[];
}) => {
  const { user, currentProjectId, changeProject, changeRole } = useUser();
  const { rolesMap } = useRoles();

  const [selectedProjectId, setSelectedProjectId] = useState(currentProjectId);
  const [selectedRole, setSelectedRole] = useState(user?.selectedRole);
  const [isChanging, setIsChanging] = useState(false);

  const availableRoles = getAvailableRoles(
    user?.roleToProjectsMappings,
    rolesMap,
    selectedProjectId
  );

  const hasAdminRole = availableRoles.some((x) => !x.isProjectRole);

  const availableProjects = hasAdminRole
    ? projects
    : Array.from(
        Object.values(user?.roleToProjectsMappings || {}).reduce<Project[]>(
          (agg, value) => {
            (value?.ids || []).forEach((id) => {
              const project = projects.find((x) => x.id === id);
              if (project) {
                agg.push(project);
              }
            });

            return agg;
          },
          []
        )
      );

  return (
    <>
      <FormControl size='small' className='flex-1'>
        <FormLabel>Select project</FormLabel>
        <Select
          MenuProps={{ disablePortal: true }}
          size='small'
          value={selectedProjectId}
          onChange={(event) => {
            setSelectedProjectId(event.target.value);
            const availableRoles = getAvailableRoles(
              user?.roleToProjectsMappings,
              rolesMap,
              event.target.value
            );
            if (availableRoles.every((x) => x.id !== selectedRole?.id)) {
              setSelectedRole(availableRoles[0]);
            }
          }}
        >
          {availableProjects
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((project) => (
              <MenuItemMui key={project.id} value={project.id}>
                {project.name}
              </MenuItemMui>
            ))}
        </Select>
      </FormControl>
      <FormControl size='small' className='flex-1'>
        <FormLabel>Select role</FormLabel>
        <Select
          MenuProps={{ disablePortal: true }}
          size='small'
          value={selectedRole?.id || ''}
          onChange={(event) => setSelectedRole(rolesMap[event.target.value])}
        >
          {availableRoles.map((role) => (
            <MenuItemMui key={role.id} value={role.id}>
              {role.name}
            </MenuItemMui>
          ))}
        </Select>
      </FormControl>
      <Stack direction='row' justifyContent='end'>
        <Button className='mr-[12px]' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant='outlined'
          disabled={
            isChanging ||
            (selectedRole?.id === user?.selectedRole.id &&
              selectedProjectId === currentProjectId)
          }
          onClick={() => {
            setIsChanging(true);
            void Promise.resolve()
              .then(async () => {
                if (
                  selectedProjectId &&
                  selectedProjectId !== currentProjectId
                ) {
                  await changeProject(selectedProjectId);
                }
              })
              .then(async () => {
                if (selectedRole && selectedRole.id !== user?.selectedRole.id) {
                  await changeRole(selectedRole.id);
                }
              })
              .then(
                () => window.location.reload(),
                () => window.location.reload()
              );
          }}
        >
          Change
        </Button>
      </Stack>
    </>
  );
};
