import { Box, styled, Typography } from '@mui/material';
import type { Control } from '@repo/api-gw-sdk';
import classNames from 'classnames';

import { useControlRules } from '@/components/controls/ControlRules';

import { supportedControlProperties } from './supportedControlProperties';

import ConditionViewer from '../conditions/ConditionViewer';

const SumBox = styled('div')({
  padding: '6px 24px',
  margin: '16px 24px',
  borderLeft: '1px solid var(--mui-palette-divider)',
  fontSize: '14px',
  lineHeight: '20px',
});

export function ControlSummary({ control }: { control: Control }) {
  const controlRules = useControlRules();

  return (
    <Box className='px-[12px] py-[24px] text-sm'>
      <Typography color='currentColor'>Resources where:</Typography>
      <SumBox>
        <ConditionViewer
          compact={true}
          condition={control.condition}
          supportProperties={supportedControlProperties.flat()}
        />
      </SumBox>
      <Typography color='currentColor'>Need to follow these rules:</Typography>
      <SumBox>
        {controlRules
          .filter(({ enabled }) => enabled(control.rules))
          .map(({ label, icon }, i) => (
            <Typography
              key={i}
              className='flex items-center my-[12px]'
              color='currentColor'
            >
              <i className={classNames([icon, 'mr-[12px]', 'text-xl'])}></i>
              <span className='leading-6'>{label(control.rules, false)}</span>
            </Typography>
          ))}
      </SumBox>
    </Box>
  );
}
