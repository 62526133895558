'use client';

import { Box } from '@mui/material';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, type PropsWithChildren } from 'react';

import { Loader } from '@/components/layout/loading';
import { useUser } from '@/contexts/useUser';

export function AuthChecker({ children }: PropsWithChildren) {
  const { sourceAccounts, isApplicationReady } = useUser();
  const router = useRouter();
  const pathname = usePathname();
  useEffect(() => {
    if (
      sourceAccounts &&
      sourceAccounts.length === 0 &&
      pathname !== '/onboarding'
    ) {
      router.push('/onboarding');
    }
  }, [sourceAccounts, pathname, router]);

  if (
    !isApplicationReady ||
    (!sourceAccounts?.length && pathname !== '/onboarding')
  ) {
    return (
      <Box
        className='h-full'
        sx={() => ({
          background: 'var(--mui-palette-background-pageGradient)',
        })}
      >
        <Loader />
      </Box>
    );
  }

  return <>{children}</>;
}
